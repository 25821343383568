import instance from "./instance";
import Cookies from "universal-cookie";

const axios = instance(process.env.REACT_APP_API_URL);

const cookies = new Cookies();

class HulloApi {
    static createRehabilitation = (name, rehabilitee, guardian, short_desc, long_desc) => {
        let config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/rehabilitation/create',
            headers: {
                'X-APP-USERID': `${cookies.get('userData')['description']['data']['id']}`,
                'X-APP-ROLE': `${cookies.get('userData')['description']['data']['role']}`,
                Authorization: `Bearer ${
                    cookies.get('userData')['description']['data']['token']
                } `,
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                name: name,
                rehabilitee: rehabilitee,
                guardian: guardian,
                short_desc: short_desc,
                long_desc: long_desc,
                schedule: [],
            })
        }
        return axios(config);
    };

    static deleteRehabilitation = rehabilitation => {
        let config = {
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/rehabilitation/' + rehabilitation,
            headers: {
                'X-APP-USERID': `${cookies.get('userData')['description']['data']['id']}`,
                'X-APP-ROLE': `${cookies.get('userData')['description']['data']['role']}`,
                Authorization: `Bearer ${
                    cookies.get('userData')['description']['data']['token']
                } `,
                'Content-Type': 'application/json',
            },
        }
        return axios(config);
    };

    static getPoints = () => {
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/points',
            headers: {
                'X-APP-USERID': `${cookies.get('userData')['description']['data']['id']}`,
                'X-APP-ROLE': `${cookies.get('userData')['description']['data']['role']}`,
                Authorization: `Bearer ${
                    cookies.get('userData')['description']['data']['token']
                } `,
                'Content-Type': 'application/json',
            },
        }
        return axios(config);
    }

    static getTransactions = () => {
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/transactions',
            headers: {
                'X-APP-USERID': `${cookies.get('userData')['description']['data']['id']}`,
                'X-APP-ROLE': `${cookies.get('userData')['description']['data']['role']}`,
                Authorization: `Bearer ${
                    cookies.get('userData')['description']['data']['token']
                } `,
                'Content-Type': 'application/json',
            },
        }
        return axios(config);
    }

}

export default HulloApi;
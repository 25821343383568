import React, { useState, useEffect } from 'react';
import useStyles from './Dashboard.style';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import { generateHelmet } from '../../utils/helperFunctions';
import Header from '../../components/General/Header/Header';
import BreadcrumbsComponent from '../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import Footer from '../../components/General/Footer/Footer';
import HulloPoints from '../../components/General/HulloPoints/HulloPoints';
import PointsBoxHistory from '../../components/Modules/Dashboard/PointsHistoryBox/PointsHistoryBox';

function Dashboard() {
  const classes = useStyles();

  const [view, setView] = useState('informacje');

  const handleViewChange = (event) => {
    setView(event.target.value);
    console.log(view);
  };
  return (
    <>
      {generateHelmet(
        'Twoj profil',
        'Najczęściej zadawane pytania aplikacji hullo'
      )}
      <Header />
      <main>
        <section>
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <BreadcrumbsComponent child="Profil" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <div className={classes.navigationButtons}>
                  <button
                    value="informacje"
                    onClick={handleViewChange}
                    className={
                      'main-info-btn' +
                      ' ' +
                      (view === 'informacje' ? 'active' : '')
                    }
                  >
                    Podstawowe informacje
                  </button>
                  {/* <button
                    value="punkty"
                    onClick={handleViewChange}
                    className={
                      'points-history-btn' +
                      ' ' +
                      (view === 'punkty' ? 'active' : '')
                    }
                  >
                    Historia punktów
                  </button> */}
                  <button
                    value="faq"
                    onClick={handleViewChange}
                    className={
                      'faq-btn' + ' ' + (view === 'faq' ? 'active' : '')
                    }
                  >
                    FAQ
                  </button>
                </div>
              </Grid>
              <Grid item md={8} xs={12}>
                <div
                  className={
                    classes.contentWrapper +
                    ' ' +
                    (view === 'informacje' ? 'active' : '')
                  }
                >
                  <Typography component={'h4'} variant={'h4'}>
                    Podstawowe informacje
                  </Typography>
                  <Typography component={'h6'} variant={'h6'}>
                    Dane konta możesz zmienić logując się na stronie{' '}
                    <a
                      href={process.env.REACT_APP_WWW_URL}
                      style={{ textDecoration: 'underline' }}
                    >
                      hullo.pl
                    </a>
                  </Typography>
                  {/*<div>
                    <div className={classes.editData}>
                      <Typography variant={'h5'}>
                        {' '}
                        Edytuj informacje{' '}
                      </Typography>
                      <div>
                        <div className={classes.input}>
                          <label>Adres email</label>
                          <TextField
                            fullWidth
                            placeholder="Adres email"
                          ></TextField>
                        </div>
                        <div className={classes.input}>
                          <label>Numer telefonu</label>
                          <TextField
                            type="number"
                            fullWidth
                            placeholder="Numer telefonu"
                          ></TextField>
                        </div>
                        <div className={classes.input}>
                          <label>Potwierdź adres email</label>
                          <TextField
                            fullWidth
                            placeholder="Potwierdź adres email"
                          ></TextField>
                        </div>
                        <div className={classes.input}>
                          <label>Hasło</label>
                          <TextField
                            type="password"
                            fullWidth
                            placeholder="Hasło"
                          ></TextField>
                        </div>
                        <div className={classes.input}>
                          <label>Potwierdź hasło</label>
                          <TextField
                            type="password"
                            fullWidth
                            placeholder="Potwierdź hasło"
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <Button>Zapisz dane</Button>
                  </div>*/}
                </div>

                <div
                  className={
                    classes.contentWrapper +
                    ' ' +
                    (view === 'punkty' ? 'active' : '')
                  }
                >
                  <Typography component={'h4'} variant={'h4'}>
                    Historia punktów
                  </Typography>
                  <div>
                    {/*<HulloPoints button />*/}
                    <div className={classes.pointsTable}>
                      <div className="labels">
                        <div className="points">
                          <Typography variant={'body2'}>
                            Ilość punktów
                          </Typography>
                        </div>
                        <div className="date">
                          <Typography variant={'body2'}>Data</Typography>
                        </div>
                        <div className="comment">
                          <Typography variant={'body2'}>Opis</Typography>
                        </div>
                        <div className="coach">
                          <Typography variant={'body2'}>
                            Dodane przez
                          </Typography>
                        </div>
                      </div>
                      <PointsBoxHistory />
                      <PointsBoxHistory />
                      <PointsBoxHistory />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    classes.contentWrapper +
                    ' ' +
                    (view === 'faq' ? 'active' : '')
                  }
                >
                  <div>
                    <Typography component={'h4'} variant={'h4'}>
                      FAQ
                    </Typography>
                    <Typography component={'h6'} variant={'h6'}>
                      Odpowiedzi na najpopularniejsze pytania znajdziesz na{' '}
                      <a
                        href={process.env.REACT_APP_WWW_URL + '/faq/'}
                        style={{ textDecoration: 'underline' }}
                      >
                        hullo.pl
                      </a>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Dashboard;

import React from 'react';
import theme from '../../../../theme';
import { createUseStyles } from 'react-jss';
import arrow from '../../../../assets/svg/arrow.svg';
import arrowTurquoise from '../../../../assets/svg/arrow-turquoise.svg';

const useStyles = createUseStyles({
  slider: {
    backgroundColor: 'white',
    boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '1.4rem',
    // padding: '0 3.5rem 2rem 3.5rem',
    padding: '2rem 3.5rem',
    marginTop: '3rem',
    '@media(max-width:600px)': {
      padding: '1.5rem',
    },
    '& .slick-slider': {
      top: '-3rem',
    },
    '& button.slick-arrow': {
      backgroundColor: '#27BDBE;',
      width: '5rem',
      height: '5rem',
      borderRadius: '100%',
      zIndex: '1',
      boxShadow: '0px 12px 25px rgba(0, 0, 0, 0.1)',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
      border: '2px solid #27BDBE',
      transition: 'all 250ms ease',
      top: '50%',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        content: '""',
        width: '2rem',
        height: '1.6rem',
        display: 'block',
        backgroundImage: `url(${arrow})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'all 250ms ease',
      },
      '&.slick-next': {
        right: '-2.3rem',
        '&:after': {
          transform: 'rotate(180deg)',
        },
      },
      '&.slick-prev': {
        left: '-2.3rem',
      },
      '&:hover': {
        backgroundColor: 'white',
        '&:after': {
          backgroundImage: `url(${arrowTurquoise})`,
        },
      },
    },
    '& .text-wrapper': {
      padding: ' 1rem',
    },
    '& .video-wrapper': {
      marginTop: '2rem',
      '& iframe': {
        maxWidth: '100%',
      },
    },
  },

  slide: {
    // border: '1px solid red',
    marginTop: '2rem',
    // backgroundColor: 'white',
    // boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.05)',
    // borderRadius: '1.4rem',
    // padding: '2rem 3.5rem',
    paddingTop: '0',
    '& .image-wrapper': {
      borderRadius: '1.4rem',
      overflow: 'hidden',
      position: 'relative',
      paddingTop: '44%',
      marginBottom: '2.5rem',
      marginTop: '-2rem',
      '& img': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        objectFit: 'cover',
      },
      '& .mask': {
        position: 'absolute',
        top: '20%',
        left: '-10%',
        width: ' 120%',
        height: ' 110%',
        transform: 'matrix(0.98, -0.15, 0.2, 0.99, 0, 0)',
        background:
          'linear-gradient(360deg, #27BDBE 7.36%, rgba(39, 183, 190, 0) 88.57%)',
      },
    },
  },
});

export default useStyles;

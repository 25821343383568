import React from 'react';
import useStyles from './Home.style';
import {
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import Header from '../../components/General/Header/Header';
import { Helmet } from 'react-helmet';
import SectionHeader from '../../components/General/SectionHeader/SectionHeader';
import NewsFeed from '../../components/Modules/News/NewsFeed/NewsFeed';
import HelloBox from '../../components/General/HelloBox/HelloBox';
import Footer from '../../components/General/Footer/Footer';
import TaskList from '../../components/Modules/Rehabilitation/TaskList/TaskList';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import checkUserCookie from '../../api/checkUserCookie';
import { generateHelmet } from '../../utils/helperFunctions';
import ProgressStats from '../../components/Modules/Rehabilitation/ProgressStats/ProgressStats';
import HulloPoints from '../../components/General/HulloPoints/HulloPoints';

function Home() {
  const classes = useStyles();
  const { t } = useTranslation();
  const cookies = new Cookies();

  return (
    <>
      {generateHelmet('Aplikacja hullo', 'Zaloguj się do aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container fixed>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <HelloBox />
                <SectionHeader
                  header={'Aktualności'}
                  buttonUrl={'/aktualnosci'}
                />
                <NewsFeed />
                {/*{checkUserCookie() &&
                t(cookies.get('userData')['description']['data']['role']) ===
                  'Rehabilitowany' ? (
                  <>
                    <SectionHeader
                      header={'Ćwiczenia do wykonania'}
                      buttonUrl={'/cwiczenia'}
                    />
                    <TaskList />
                  </>
                ) : (
                  ''
                )}*/}

                {/*{checkUserCookie() &&
                t(cookies.get('userData')['description']['data']['role']) !==
                  'Rehabilitowany' ? (
                  <>
                    <SectionHeader
                      header={'Lista podopiecznych'}
                      buttonUrl={'/'}
                    />
                    <Typography>
                      Lista podpopiecznych danego trenera do uzupełnienia w API
                    </Typography>
                  </>
                ) : (
                  ''
                )}*/}
              </Grid>
              <Grid item md={4} xs={12}>
                <HulloPoints />
                <ProgressStats />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Home;

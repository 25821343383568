import React from 'react';
import theme from '../../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  box: {
    '& .MuiCardContent-root': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .avatar': {
      marginRight: '2rem',
      width: '7.8rem',
      height: '7.8rem',
      border: '2px solid #F8AA9E',
      borderRadius: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#F8AA9E',
      padding: '4px',
      '& div': {
        width: '100%',
        height: '100%',
        borderRadius: '100%',
        backgroundColor: '#FEEEEC',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '& .content-wrapper': {
      '& h1': {
        color: '#27BDBE',
      },
    },
  },
});

export default useStyles;

import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  outerBox: {
    // border: '1px solid red',
    '& > div.id-box': {
      backgroundColor: 'white',
      borderRadius: '1.4rem 1.4rem 0 0',
      padding: '1.5rem',
      display: 'inline-flex',
      borderBottom: '2px solid #D9DCDE',
    },
  },
  box: {
    backgroundColor: 'white',
    borderRadius: '0 1.4rem 1.4rem 1.4rem',
    padding: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      // border: '1px solid red',
      display: 'flex',
      justifyContent: 'center',
    },
    '& .status': {
      width: 'calc(1 / 12 * 100%)',
      '& span.active': {
        color: 'green',
      },
      '& span.inactive': {
        color: 'red',
      },
    },
    '& .title': {
      width: 'calc(2 / 12 * 100%)',
    },
    '& .rehabilitator': {
      width: 'calc(2 / 12 * 100%)',
    },
    '& .progress': {
      // width: 'calc(2 / 12 * 100%)',
      padding: '0 5px',
      position: 'relative',
      '& p': {
        position: 'absolute',
        fontSize: '1.4rem',
        left: '7px',
        top: '-22px',
        margin: '0',
      },
      '@media(max-width:900px)': {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        '& p': {
          position: 'relative',
          left: '0',
          top: '0',
          margin: '0',
        },
      },
    },
    '& .date': {
      width: 'calc(2 / 12 * 100%)',
    },
    '& .button': {
      // width: 'calc(1 / 12 * 100%)',
      width: 'calc(3 / 12 * 100%)',
    },
    '@media(max-width:900px)': {
      // backgroundColor: 'yellow',
      flexWrap: 'wrap',
      '& >div': {
        // border: '1px solid black',
        width: '50% !important',
        padding: '1rem',
      },
    },
    '@media(max-width:600px)': {
      '& >div': {
        width: '100% !important',
      },
    },
  },
});

export default useStyles;

import React from 'react';
import theme from '../../../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  article: {
    // border: '1px solid red',
    '& .image-wrapper': {
      position: 'relative',
      // paddingTop: 'calc(57.15% * 0.9)',
      paddingTop: '57.15% ',

      overflow: 'hidden',
      borderRadius: '1.4rem 1.4rem 0 0',
      // width: '90%',
      '& a': {
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        '& img': {
          width: ' 100%',
          height: ' 100%',
          objectFit: 'cover',
        },
        // '& .mask': {
        //   position: 'absolute',
        //   top: '5%',
        //   left: '-10%',
        //   width: ' 120%',
        //   height: ' 110%',
        //   transform: 'matrix(0.96, 0.21, -0.27, 0.98, 0, 0)',
        //   background:
        //     'linear-gradient(360deg, #009ddb 7.36%, rgba(0, 157, 219, 0) 88.57%)',
        // },
      },
    },
    '& .text-wrapper': {
      position: 'relative',
      // marginTop: '-20%',
      // width: '83%',
      // left: '17%',
      borderRadius: '0 0 1.4rem 1.4rem',
      boxShadow: '0px 12px 10px rgb(0 0 0 / 5%)',
      backgroundColor: 'white',
      padding: '2rem 3rem',
      // marginTop: '2rem',
      '& h6': {
        margin: '1.5rem 0',
      },
      '& span': {
        color: '#27bdbe',
      },
      '& p': {
        marginBottom: '2rem',
      },
      '& a': {
        textDecoration: 'none',
      },
    },
  },
});

export default useStyles;

import React from 'react';
import theme from '../../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    '& .MuiGrid-container': { marginTop: '0' },
    '& .MuiGrid-container:first-of-type': {
      '& .MuiGrid-item': {
        paddingTop: '0',
      },
    },
    '& .MuiGrid-container:nth-of-type(2)': {
      '& .MuiGrid-item': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2rem',
        '& h1': {
          marginRight: '4rem',
        },
      },
    },
  },
  buttonsWrapper: {
    background: '#D9DCDE',
    borderRadius: '4.8rem',
    padding: '1rem',
    display: 'inline-flex',
    '& button': {
      borderRadius: '5.2rem',
      border: 'none',
      padding: '1.4rem 2.8rem',
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '1.4rem',
      color: '#42515a',
      backgroundColor: 'transparent',
      '&:first-of-type': {
        marginRight: '1rem',
      },
      '&.active': {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 12px 25px rgba(0, 0, 0, 0.1)',
        color: '#27BDBE',
      },
      cursor: 'pointer',
    },
  },
  gamesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -1rem',
    '& .wrapper-inner': {
      width: '33.33%',
      padding: '0 1rem 2rem 1rem',
      '@media(max-width:900px)': {
        width: '50%',
      },
      '@media(max-width:600px)': {
        width: '100%',
      },
    },
  },
});

export default useStyles;

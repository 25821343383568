import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  box: {
    textDecoration: 'none',
    padding: '2.5rem',
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.05)',

    '& .upper-row': {
      display: 'flex',
      alignItems: 'center',
      '& div:last-of-type': {
        '& span': {
          color: '#27BDBE',
        },
      },
      '& .circle-wrapper': {
        position: 'relative',
        display: 'block',
        marginRight: '1rem',

        '& .percentage-wrapper': {
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      '& svg': {
        '& circle': {
          stroke: '#27BDBE',
        },
      },
    },
    '& .lower-row': {
      display: 'flex',
      '& div': {
        width: '50%',
        maxWidth: '11rem',
        '& h4': {
          color: '#27BDBE',
          marginBottom: '0',
        },
        '& span': {
          lineHeight: '1',
        },
      },
    },
    '& .dates-row': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1rem',
      '& span': {
        marginTop: '0.5rem',
        lineHeight: '1.5',
      },
    },
  },
});

export default useStyles;

import React, { useState, useEffect } from 'react';
import useStyles from './Exercises.style';
import {
  Container,
  Grid,
  Typography,
  Button,
  Breadcrumbs,
} from '@mui/material';
import { generateHelmet } from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import ExcerciseCard from '../../../components/Modules/Excercises/ExcerciseCard/ExcerciseCard';
import ExcerciseSlajder from '../../../components/Modules/Excercises/ExerciseSlajder/ExerciseSlajder';
import Footer from '../../../components/General/Footer/Footer';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import getCpt from '../../../api/getCpt';
import { useParams } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';

function SingleExercise() {
  const classes = useStyles();

  let params = useParams();
  const [post, setPost] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCpt(`cwiczenia?slug=${params.exerciseSlug}`).then((result) => {
      setPost(result[0]);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {generateHelmet('Cwiczenia', 'Moduł ćwiczeniowy aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container>
            {loading && (
              <div className={classes.loaderWrapper}>
                <BallTriangle
                  heigth="70"
                  width="70"
                  color="#27BDBE"
                  ariaLabel="loading-indicator"
                />
              </div>
            )}
            {!loading && (
              <>
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <BreadcrumbsComponent
                      parent="Ćwiczenia"
                      parentLink="/cwiczenia"
                      child={post.title.rendered}
                    />
                  </Grid>
                  <Grid item lg={8} md={7} xs={12}>
                    <ExcerciseSlajder
                      title={post.title.rendered}
                      description={
                        post.acf.hasOwnProperty('opis_cwiczenia')
                          ? post.acf.opis_cwiczenia
                          : ''
                      }
                      galeria={
                        post.acf.hasOwnProperty('galeria_zdjec')
                          ? post.acf.galeria_zdjec
                          : ''
                      }
                      videoUrl={
                        post.acf.hasOwnProperty('vimeo_embed')
                          ? post.acf.vimeo_embed
                          : 'https://player.vimeo.com/video/561548769?h=3a09b99d5b'
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={5} xs={12}>
                    {/* <div className={classes.ratesBox}>
                      <Typography variant={'h4'}>Oceń ćwiczenie</Typography>
                    </div> */}
                    <div className={classes.contactBox}>
                      <Typography variant={'h6'}>
                        Masz pytania odnośnie ćwiczenia?
                      </Typography>
                      <Typography variant={'subtitle2'}>
                        Napisz wiadomość a nasi trenerzy postarają się jak
                        najszybciej Ci odpowiedzieć
                      </Typography>
                      <a href="mailto:recepcja@hullocentrum.pl">
                        <Button>recepcja@hullocentrum.pl</Button>
                      </a>
                    </div>
                  </Grid>
                </Grid>
                {/*<Grid container spacing={3}>*/}
                {/*  <Grid item lg={2} xs={12}></Grid>*/}
                {/*  <Grid item lg={8} xs={12}></Grid>*/}
                {/*  <Grid item lg={2} xs={12}></Grid>*/}
                {/*</Grid>*/}
              </>
            )}{' '}
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default SingleExercise;

import React from 'react';
import { createUseStyles } from 'react-jss';
import popular from '../../../../assets/svg/popular.svg';

const useStyles = createUseStyles({
  gameCard: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '1.8rem',
    paddingTop: '0',
    '& .image-wrapper': {
      borderRadius: ' 0 0 1.4rem 1.4rem',
      position: 'relative',
      //paddingTop: '80%',
      overflow: 'hidden',

      '& img': {
        position: 'relative',
        width: '100%',
        //height: '100%',
        top: '0',
        left: '0',
        backgroundSize: 'cover',
      },
      '& .mask': {
        display: 'none',
        position: 'absolute',
        top: '20%',
        left: '-10%',
        width: ' 120%',
        height: ' 110%',
        transform: 'matrix(0.98, -0.15, 0.2, 0.99, 0, 0)',
        background:
          'linear-gradient(360deg, #F3A2A2 7.36%, rgba(243, 162, 162, 0) 88.57%)',
      },
      '& .popular': {
        position: 'absolute',
        bottom: '5%',
        right: '5%',
        padding: '5px',
        backgroundColor: '#DA1F3C',
        lineHeight: 1,
        borderRadius: '5px',
        '& p': {
          letterSpacing: '0.4px',
          color: 'white',
          margin: '0',
          fontSize: '1.2rem',
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
          '&:after': {
            content: ' ""',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: '1.8rem',
            height: '1rem',
            marginLeft: '1rem',
            backgroundImage: `url(${popular})`,
          },
        },
      },
    },
    '& .text-wrapper': {
      paddingTop: '2rem',
      '& >p': {
        color: '#42515A',
        position: 'relative',

        '&:before': {
          content: '""',
          height: '100%',
          width: '4px',
          backgroundColor: '#27BDBE',
          position: 'absolute',
          borderRadius: '0px 3px 3px 0px',
          left: '-2rem',
        },
      },
    },
  },
  pointsWrapper: {
    margin: '1rem 0',
    '& h4': {
      marginBottom: '0',
      color: '#27BDBE',
      lineHeight: '1',
    },
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    width: '53%',
    margin: 'auto',
    padding: '1.8rem',
    position: 'relative',
    marginTop: '5rem',
    marginBottom: '5rem',
    '@media(max-width:600px)': {
      width: '90%',
    },
  },
  image: {
    //paddingTop: '45%',
    borderRadius: '1.4rem',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      position: 'relative',
      top: '0',
      left: '0',
      width: '100%',
      //height: '100%',
      objectFit: 'cover',
    },
    '& .mask': {
      display: 'none',
      position: 'absolute',
      top: '20%',
      left: '-10%',
      width: ' 120%',
      height: ' 110%',
      transform: 'rotate(10.39deg)',
      background:
        'linear-gradient(360deg, #FFCB04 7.36%, rgba(255, 203, 4, 0) 88.57%)',
    },
  },

  text: {
    marginTop: '2rem',
    '& h6': {
      marginBottom: '1rem',
      color: '#42515A',
    },
    '& p': {
      color: '#68747B',
    },
    '& .unblock-game': {
      display: 'flex',
      alignItems: 'center',
      '& div': {
        marginRight: '2rem',
      },
    },
  },
  closeModal: {
    position: 'absolute',
    top: '0',
    right: '-6rem',
    backgroundColor: 'transparent',
    width: '5.2rem',
    height: '5.2rem',
    padding: '0',
    border: 'none',
    zIndex: '1',
    '&:hover': {
      cursor: 'pointer',
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    '@media(max-width:768px)': {
      right: '0',
    },
  },
});

export default useStyles;

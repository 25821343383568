import React, { useEffect, useState } from 'react';
import useStyles from './AdminSingleTherapy.style';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import getUserName from '../../../../api/getUserName';

function AdminSingleTherapy({
  rehabilitationID,
  rehabilitationTitle,
  slug,
  userID = '0',
  status,
  createdDate,
  updatedDate,
}) {
  const classes = useStyles();

  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (userID !== '0') {
      getUserName(`${userID}`).then((result) => {
        setUserName(result.name);
      });
    }
  }, []);

  return (
    <div className={classes.outerBox}>
      <div className="id-box">
        <Typography variant={'caption'}>ID: {rehabilitationID}</Typography>
      </div>
      <div className={classes.box}>
        <div className="status">
          <Typography variant={'body2'}>{status}</Typography>{' '}
        </div>
        <div className="title">
          {' '}
          <Typography variant={'body2'}>{rehabilitationTitle}</Typography>{' '}
        </div>
        <div className="rehabilitator">
          {' '}
          <Typography variant={'body2'}>{userName}</Typography>{' '}
        </div>
        {/* <div className="progress">
          <LinearProgress variant="determinate" value={50}></LinearProgress>
          <p>50%</p>
        </div> */}
        <div className="date">
          <Typography variant={'body2'}>{createdDate}</Typography>
        </div>
        <div className="date">
          <Typography variant={'body2'}>{updatedDate}</Typography>
        </div>
        <div className="button">
          <Link to={slug}>
            <IconButton>
              <ArrowForwardIcon />
            </IconButton>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AdminSingleTherapy;

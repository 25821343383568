import React, { useState, useEffect } from 'react';
import useStyles from './Rehabilitation.style';
import { Button, Container, Grid, Typography } from '@mui/material';
import { generateHelmet } from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import Footer from '../../../components/General/Footer/Footer';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import SingleTherapy from '../../../components/Modules/Rehabilitation/SingleTherapy/SingleTherapy';
import SingleComment from '../../../components/Modules/Rehabilitation/SingleComment/SingleComment';
import { Link } from 'react-router-dom';
import AdminSingleTherapy from '../../../components/Modules/Rehabilitation/AdminSingleTherapy/AdminSingleTherapy';
import LastCommentsBox from '../../../components/Modules/Rehabilitation/LastCommentsBox/LastCommentsBox';
import getRehabilitation from '../../../api/getRehabilitation';
import { BallTriangle } from 'react-loader-spinner';
import checkUserCookie from '../../../api/checkUserCookie';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

function RehabilitationIndex() {
  const classes = useStyles();
  const cookies = new Cookies();
  const { t } = useTranslation();

  const [rehabilitationList, setRehabilitationList] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (checkUserCookie()) {
      getRehabilitation(
        `/rehabilitation/list`
        // `${cookies.get('userData')['description']['data']['id']}`,
        // `${cookies.get('userData')['description']['data']['role']}`,
        // `${cookies.get('userData')['description']['data']['token']}`
      ).then((result) => {
        setRehabilitationList(result);
        setLoading(false);
        console.log(result);
      });
    }
  }, []);

  // useEffect(() => {
  //   getRehabilitation(
  //     `/rehabilitation/list`,
  //     '1',
  //     'rehabilitee',
  //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvc21pbGUuZmFzdGRldi5wbCIsImlhdCI6MTY1NDY5MzQzOSwibmJmIjoxNjU0NjkzNDM5LCJleHAiOjE2NTUyOTgyMzksImRhdGEiOnsidXNlciI6eyJpZCI6MiwiZGV2aWNlIjoiIiwicGFzcyI6IjI0MDk1MzNiNGE2NDFlYTY4OTY0MWJlYTlhMDU5ODgyIn19fQ.F7okDObNQtI0w-c2q5U5h7CxZx_IrQftWobwFtjFv0U'
  //   ).then((result) => {
  //     setRehabilitationList(result);
  //     setLoading(false);
  //     console.log(result);
  //   });
  // }, []);

  return (
    <>
      {generateHelmet('Rehabilitacje', 'Moduł rehabilitacyjny aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <BreadcrumbsComponent child="Rehabilitacje" />
              </Grid>
            </Grid>
            {!checkUserCookie() ? (
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12} className={classes.titleRow}>
                  <Typography variant={'h4'}>
                    Zaloguj się, aby zobaczyć rehabilitacje
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12} className={classes.titleRow}>
                    <Typography variant={'h4'}>Lista terapii</Typography>

                    {checkUserCookie() &&
                      t(
                        cookies.get('userData')['description']['data']['role']
                      ) !== 'Rehabilitowany' && (
                        <Button>
                          <Link to="/rehabilitacje/nowa-rehabilitacja">
                            Dodaj rehabilitację
                          </Link>
                        </Button>
                      )}
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  {loading && (
                    <div className={classes.loaderWrapper}>
                      <BallTriangle
                        heigth="70"
                        width="70"
                        color="#27BDBE"
                        ariaLabel="loading-indicator"
                      />
                    </div>
                  )}

                  {checkUserCookie() &&
                  t(cookies.get('userData')['description']['data']['role']) !==
                    'Rehabilitowany' ? (
                    <>
                      <Grid item lg={12} xs={12}>
                        <div className={classes.tableTherapy}>
                          <div className="labels">
                            <div className="status">
                              <p>Status</p>
                            </div>
                            <div className="title">
                              <p>Tytuł</p>
                            </div>
                            <div className="rehabilitator">
                              <p>Rehabilitowany</p>
                            </div>
                            {/* <div className="progress">
                              <p>Progres</p>
                            </div> */}
                            <div className="date">
                              <p>Data utworzenia</p>{' '}
                            </div>
                            <div className="date">
                              <p>Data edycji</p>{' '}
                            </div>
                            <div className="button">
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}

                  {!loading &&
                    (rehabilitationList.length
                      ? rehabilitationList.map(
                          (rehabilitation) =>
                            (rehabilitation =
                              checkUserCookie() &&
                              t(
                                cookies.get('userData')['description']['data'][
                                  'role'
                                ]
                              ) === 'Rehabilitowany' ? (
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                  <SingleTherapy
                                    rehabilitationTitle={
                                      rehabilitation.name !== null
                                        ? rehabilitation.name
                                        : ''
                                    }
                                    rehabilitationID={rehabilitation.rehab_id}
                                    slug={rehabilitation.rehab_id}
                                    key={rehabilitation.rehab_id}
                                    status={
                                      rehabilitation.status === 1
                                        ? 'aktywna'
                                        : 'nieaktywna'
                                    }
                                    createdDate={rehabilitation.created_at.slice(
                                      0,
                                      10
                                    )}
                                    updatedDate={rehabilitation.updated_at.slice(
                                      0,
                                      10
                                    )}
                                  />
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  lg={12}
                                  xs={12}
                                  className={classes.tableBodyRows}
                                >
                                  <div className={classes.tableTherapy}>
                                    <AdminSingleTherapy
                                      slug={rehabilitation.rehab_id}
                                      key={rehabilitation.rehab_id}
                                      userID={rehabilitation.rehabilitee}
                                      rehabilitationID={rehabilitation.rehab_id}
                                      rehabilitationTitle={
                                        rehabilitation.name !== null
                                          ? rehabilitation.name
                                          : ''
                                      }
                                      status={
                                        rehabilitation.status === 1 ? (
                                          <span className="active">
                                            aktywna
                                          </span>
                                        ) : (
                                          <span className="inactive">
                                            nieaktywna
                                          </span>
                                        )
                                      }
                                      createdDate={rehabilitation.created_at.slice(
                                        0,
                                        10
                                      )}
                                      updatedDate={rehabilitation.updated_at.slice(
                                        0,
                                        10
                                      )}
                                    />
                                  </div>
                                </Grid>
                              ))
                        )
                      : 'Brak terapii')}

                  {/* <Typography variant={'h6'}>
                    Reheabilitacje nieaktywne
                  </Typography> */}
                </Grid>

                {/* <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <Typography variant={'h4'}>Ostatnie komentarze</Typography>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <SingleComment></SingleComment>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <SingleComment></SingleComment>
                  </Grid>
                </Grid> */}

                {/* <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <div className={classes.tableTherapy}>
                      <div className="labels">
                        <div className="status">
                          <p>Status</p>
                        </div>
                        <div className="rehabilitator">
                          <p>Rehabilitowany</p>
                        </div>
                        <div className="progress">
                          <p>Progres</p>
                        </div>
                        <div className="date">
                          <p>Data utworzenia</p>{' '}
                        </div>
                        <div className="date">
                          <p>Data edycji</p>{' '}
                        </div>
                        <div className="button">
                          <p></p>
                        </div>
                      </div>
                      <AdminSingleTherapy />
                      <AdminSingleTherapy />
                      <AdminSingleTherapy />
                    </div>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                <LastCommentsBox></LastCommentsBox>
              </Grid>
                </Grid> */}
              </>
            )}

            {/*
            <Grid container spacing={3}>
              <Grid item lg={7} xs={12}>
                <Typography variant={'h4'}>Dodaj nową rehabilitację</Typography>
              </Grid>
              <Grid item lg={5} xs={12}>
                <Typography variant={'h5'}>Inne rehabilitacje</Typography>
              </Grid>
            </Grid>
           */}
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default RehabilitationIndex;

import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    '& .points': {
      padding: '2.8rem',
      backgroundColor: '#D4F2F2',
      borderRadius: '1.4rem',
      color: '#27BDBE',
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: '1.5rem',
    },
    '& .text': {
      display: 'inline-flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
});

export default useStyles;

import React from 'react';
import theme from '../../../theme';
import { createUseStyles } from 'react-jss';
import news from '../../../assets/svg/nav-news.svg';
import rehabilitation from '../../../assets/svg/nav-rehabilitation.svg';
import exercises from '../../../assets/svg/nav-exercises.svg';
import bundles from '../../../assets/svg/nav-bundles.svg';
import games from '../../../assets/svg/nav-games.svg';
import contact from '../../../assets/svg/nav-contact.svg';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    width: '100%',
    zIndex: 1000,
    top: 0,
    // paddingTop: '3rem',

    '& .component': {
      '&.user-info': {
        display: 'block',
        position: 'relative',
        height: '100%',
        //background: 'red',
        margin: '0 2rem',
        borderRight: 'solid 2px ' + theme.palette.grey['50'],
        padding: '1rem 2rem',
        '@media(max-width:900px)': {
          margin: '0 1rem',
        },
        '@media(max-width:550px)': {
          padding: '1rem !important',
          margin: '0 !important',
        },
        '@media(max-width:500px)': {
          padding: '0 !important',
        },
        '& .component-inner': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',

          '& .image-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '48px',
            marginRight: '1rem',

            '& img': {
              width: '100%',
              maxWidth: '100%',
              display: 'block',
              position: 'relative',
              padding: '2px',
              border: 'solid 2px ' + theme.palette.primary.main,
              borderRadius: '48px',
            },

            '& .hover-menu': {
              opacity: 0,
              visibility: 'hidden',
              position: 'absolute',
              left: 0,
              top: 0,
              right: '-5rem',
              zIndex: 10000,
              transition: 'all ' + theme.transitions.duration.standard + 'ms',

              '& .hover-menu-content': {
                background: theme.palette.background.paper,
                border: 'solid 2px ' + theme.palette.grey['50'],
                borderRadius: '2rem' + ' !important',
                padding: '2rem' + ' !important',
                boxShadow: theme.shadows[1] + ' !important',
              },
            },
            '&:hover .hover-menu': {
              top: '-1rem',
              visibility: 'visible',
              opacity: 1,
              transition: 'all ' + theme.transitions.duration.standard + 'ms',
            },
          },
          '& .content-wrapper': {
            '@media(max-width:500px)': {
              display: 'none',
            },
            '& .user-name': {
              fontWeight: theme.typography.fontWeightSemiBold,
            },
            '& .user-role': {
              fontSize: '1.2rem',
              lineHeight: 1,
              fontWeight: theme.typography.fontWeightSemiBold,
            },
          },
        },
      },
    },
  },
  headerItem: {
    marginRight: 0 + ' !important',
    padding: 0 + ' !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoItem: {
    padding: '1rem 0' + ' !important',
    marginRight: 'auto !important',
    '& a': {
      display: 'block',
      position: 'relative',
      height: '5rem',

      '& img': {
        height: '100%',
        maxHeight: '100%',
      },
      '@media(max-width:900px)': {
        height: '4rem',
      },
      '@media(max-width:600px)': {
        height: '3rem',
      },
    },
  },
  resizeLettersItem: {
    borderRight: 'solid 2px #eef1f3',
    marginRight: '2rem !important',
    height: '7rem',
    paddingRight: '2rem !important',
    '& button': {
      border: 'none',
      backgroundColor: 'transparent',
      fontSize: '1.6rem',
      fontWeight: '600',
      color: '#42515A',
      cursor: 'pointer',
      '&:first-of-type': {
        fontSize: '1.4rem',
      },
      '&:last-of-type': {
        fontSize: '1.8rem',
      },
    },
    '@media(max-width:900px)': {
      paddingRight: '1rem !important',
    },
    '@media(max-width:550px)': {
      paddingRight: '0 !important',
      marginRight: '1rem !important',
    },
  },
  headerContainer: {
    background: theme.palette.background.paper,
    borderRadius: '2rem' + ' !important',
    padding: 0 + ' !important',
    boxShadow: theme.shadows[1] + ' !important',
  },
  upperHeader: {
    width: '100% !important',
    margin: 0 + ' !important',
    padding: '0 2rem' + ' !important',
    display: 'flex',
    alignItems: 'center !important',

    // border: '1px solid red',

    '& a': {
      textDecoration: 'none',
    },
    '& .hamburger-react': {
      display: 'none',
      '& div:nth-of-type(2)': {
        width: '25px !important',
      },
      '@media(max-width:900px)': {
        display: 'block',
      },
      // '@media(max-width:340px)': {
      //   '& div': {
      //     width: '28px !important',
      //   },
      // },
    },
    '& .login-item': {
      '@media(max-width:900px)': {
        display: 'none',
      },
    },
  },
  navItem: {
    position: 'relative',
    margin: '0 auto !important',
    padding: 0 + ' !important',
    '& a': {
      display: 'block',
      position: 'relative',
      margin: '0 !important',
      textDecoration: 'none',
      fontWeight: 700,
      color: theme.palette.text.primary,
      padding: '1.5rem 1rem' + ' !important',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:before': {
        content: ' ""',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '2rem',
        height: '2rem',
        marginRight: '1rem',
        backgroundImage: `url(${news})`,
      },
    },
    '&:nth-of-type(2)': {
      '& a': {
        '&:before': {
          backgroundImage: `url(${rehabilitation})`,
        },
      },
    },
    '&:nth-of-type(3)': {
      '& a': {
        '&:before': {
          backgroundImage: `url(${exercises})`,
        },
      },
    },
    '&:nth-of-type(4)': {
      '& a': {
        '&:before': {
          backgroundImage: `url(${bundles})`,
        },
      },
    },
    '&:nth-of-type(5)': {
      '& a': {
        '&:before': {
          backgroundImage: `url(${games})`,
        },
      },
    },
    '&:nth-of-type(6)': {
      '& a': {
        '&:before': {
          backgroundImage: `url(${contact})`,
        },
      },
    },
    '&:hover': {
      color: '""',
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '4px',
        backgroundColor: '#27BDBE',
      },
    },
  },
  lowerHeader: {
    backgroundColor: theme.palette.grey['50'],
    width: '100% !important',
    margin: 0 + ' !important',
    padding: '0rem 2rem' + ' !important',
    borderBottomLeftRadius: '2rem' + ' !important',
    borderBottomRightRadius: '2rem' + ' !important',

    '& .login-item': {
      display: 'none !important',
      '@media(max-width:900px)': {
        display: 'flex !important',
        marginBottom: '2rem',
      },
    },

    '@media(max-width:900px)': {
      // border: '1px solid red',
      flexDirection: 'column !important',
      maxHeight: '0',
      overflow: 'hidden',
      transition: 'max-height 250ms ease',

      '& >div': {
        width: '100% !important',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    '&.active-menu': {
      maxHeight: '100vh',
    },
  },
  fundingItem: {
    display: 'flex',
    padding: '2rem !important',
    border: 'solid 2px ' + theme.palette.grey['50'],
    backgroundColor: 'white',
    alignItems: 'center',
    marginBottom: '2rem',
    '& img': {
      maxWidth: '100%',
    },
    '@media(max-width:992px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',

      '& p': {
        marginTop: '1rem',
      },
    },
    '@media(max-width:576px)': {
      '& p': {
        fontSize: '8px',
        lineHeight: '1.25',
      },
    },
  },
});

export default useStyles;

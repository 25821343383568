import React, { useState, useEffect } from 'react';
import useStyles from './TaskGroup.style';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';

import closeModal from '../../../../assets/svg/close-modal.svg';
import Modal from '@mui/material/Modal';
import removeTask from '../../../../assets/svg/remove-task.svg';
import dateIcon from '../../../../assets/svg/date.svg';
import taskNumber from '../../../../assets/svg/task-number.svg';
import SingleTask from '../SingleTask/SingleTask';
import updateTaskStatus from '../../../../api/updateTaskStatus';

function TaskGroup({
  date,
  exercises,
  id,
  rehabId,
  setRefreshSchedule,
  setScheduleLoading,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [exercisesProp, setExercisesProp] = React.useState([...exercises]);
  const [exercisesChanged, setExercisesChanged] = React.useState(false);

  const [buttonText, setButtonText] = useState('Oznacz jako zrobione');

  function handleClose() {
    setOpen(false);
    if (exercisesChanged) {
      setScheduleLoading(true);
      updateTaskStatus(rehabId, id, exercisesProp, setRefreshSchedule);
    }
  }

  return (
    <div className={classes.box}>
      <div>
        <Typography variant={'body2'}>{date}</Typography>
        <Typography variant={'h6'}>
          Liczba ćwiczeń: {exercisesProp.length}{' '}
        </Typography>
        <span style={{ fontSize: '10px' }}>(ID: {id})</span>
        {/* span do usunięcia */}
      </div>
      <Button onClick={handleOpen}>Wyświetl</Button>
      <Modal open={open} onClose={handleClose}>
        {/* {JSON.stringify(exercises)} */}
        <div className={classes.modalContent}>
          <button onClick={handleClose} className={classes.closeModal}>
            <img src={closeModal} alt="" />
          </button>

          <div className={classes.title}>
            <div>
              <img src={dateIcon} alt="" />
              <div>
                <Typography variant={'body1'}>Termin</Typography>
                <Typography variant={'h6'}>{date}</Typography>
              </div>
            </div>
            <div>
              <img src={taskNumber} alt="" />

              <Typography variant={'h6'}>
                Liczba zadań: {exercisesProp.length}
              </Typography>
            </div>
          </div>

          <Typography variant={'subtitle1'}>Zadania do wykonania</Typography>
          {exercisesProp.map(
            (exercise) =>
              (exercise = (
                <SingleTask
                  key={exercise.id}
                  exercise={exercise}
                  setExercises={setExercisesProp}
                  exercises={exercisesProp}
                  setExercisesChanged={setExercisesChanged}
                />
              ))
          )}
        </div>
      </Modal>
    </div>
  );
}

export default TaskGroup;

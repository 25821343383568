import Cookies from 'universal-cookie';

async function getComments(rehabId) {
  const cookies = new Cookies();

  var myHeaders = new Headers();

  myHeaders.append(
    'X-APP-USERID',
    `${cookies.get('userData')['description']['data']['id']}`
  );
  myHeaders.append(
    'X-APP-ROLE',
    `${cookies.get('userData')['description']['data']['role']}`
  );
  myHeaders.append(
    'Authorization',
    `Bearer ${cookies.get('userData')['description']['data']['token']} `
  );

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    process.env.REACT_APP_API_URL + '/rehabilitation/' + rehabId + '/comments',
    requestOptions
  );
  return await response.json();
}

export default getComments;

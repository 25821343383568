import React, { useState, useEffect } from 'react';
import useStyles from './Rehabilitation.style';
import { Alert, Button, Container, Grid, Typography } from '@mui/material';
import { generateHelmet } from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import Footer from '../../../components/General/Footer/Footer';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';

import ProgressBox from '../../../components/Modules/Rehabilitation/ProgressBox/ProgressBox';
import PointsBox from '../../../components/Modules/Rehabilitation/PointsBox/PointsBox';
import CoachBox from '../../../components/Modules/Rehabilitation/CoachBox/CoachBox';
import TherapyDetails from '../../../components/Modules/Rehabilitation/TherapyDetails/TherapyDetails';
import TaskGroup from '../../../components/Modules/Rehabilitation/TaskGroup/TaskGroup';
import Slider from 'react-slick';
import Comments from '../../../components/Modules/Rehabilitation/Comments/Comments';
import { Navigate, useParams } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import getRehabilitation from '../../../api/getRehabilitation';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import checkUserCookie from '../../../api/checkUserCookie';
import { Link } from 'react-router-dom';
import HulloApi from '../../../api/HulloApi';
import Snackbar from '@mui/material/Snackbar';
import getSchedule from '../../../api/getSchedule';
import HulloPoints from '../../../components/General/HulloPoints/HulloPoints';

function SingleRehabilitation() {
  const classes = useStyles();

  const cookies = new Cookies();
  const { t } = useTranslation();

  let params = useParams();
  const [rehabilitation, setRehabilitation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refreshSchedule, setRefreshSchedule] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');

  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [rehabilitationSchedule, setRehabilitationSchedule] = useState({});

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    getRehabilitation(`/rehabilitation/${params.rehabilitationSlug}`).then(
      (result) => {
        setRehabilitation(result);
        setLoading(false);
        // console.log(result);
      }
    );
  }, []);

  useEffect(() => {
    if (refreshSchedule) {
      setScheduleLoading(true);
      getSchedule(params.rehabilitationSlug).then((result) => {
        setRehabilitationSchedule(result);
        setScheduleLoading(false);

        setRefreshSchedule(false);
        console.log(result);
      });
    }
  }, [refreshSchedule]);

  const settings = {
    infinite: rehabilitationSchedule.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleDelete = () => {
    setLoading(true);
    HulloApi.deleteRehabilitation(params.rehabilitationSlug)
      .then((response) => {
        console.log(response);
        setSnackbarOpen(true);
        setSnackbarMessage('Rehabilitacja usunięta');
        setSnackbarType('success');

        setRedirectUrl('/rehabilitacje/');
        setRedirect(true);
        setLoading(false);
      })
      .catch((error) => {
        setSnackbarOpen(true);
        setSnackbarMessage(
          'W trakcie usuwania rehabilitacji wystąpił błąd. Spróbuj ponownie później.'
        );
        setSnackbarType('error');
        console.log(error.data);
        setLoading(false);
      });
  };

  if (redirect) {
    return <Navigate to={redirectUrl} />;
  }

  return (
    <>
      {generateHelmet('Rehabilitacje', 'Moduł rehabilitacyjny aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container>
            {loading && (
              <div className={classes.loaderWrapper}>
                <BallTriangle
                  heigth="70"
                  width="70"
                  color="#27BDBE"
                  ariaLabel="loading-indicator"
                />
              </div>
            )}

            {!loading && (
              <>
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <BreadcrumbsComponent
                      parent="Rehabilitacje"
                      parentLink="/rehabilitacje"
                      child={rehabilitation.name}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Typography variant={'h4'}>
                      {rehabilitation.name !== null ? rehabilitation.name : ''}{' '}
                    </Typography>
                    <Typography variant={'body1'}>
                      ID: {rehabilitation.rehab_id}
                    </Typography>
                    <Typography variant={'body1'}>
                      Status:{' '}
                      <b>
                        {rehabilitation.status === 1 ? 'aktywna' : 'nieaktywna'}
                      </b>
                    </Typography>
                    <Typography variant={'body1'}>
                      Data utworzenia:{' '}
                      <b>{rehabilitation.created_at.slice(0, 10)}</b>
                    </Typography>
                    <Typography variant={'body1'}>
                      Data aktualizacji:{' '}
                      <b>{rehabilitation.updated_at.slice(0, 10)}</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  {/*<Grid item lg={4} xs={12}>*/}
                  {/*  <ProgressBox />*/}
                  {/*</Grid>*/}
                  <Grid item lg={8} xs={12}>
                    {checkUserCookie() &&
                    t(
                      cookies.get('userData')['description']['data']['role']
                    ) === 'Rehabilitowany' ? (
                      <CoachBox
                        // showContact
                        role="Trener"
                        userID={rehabilitation.trainer}
                      />
                    ) : (
                      <CoachBox
                        role="Rehabilitowany"
                        userID={rehabilitation.rehabilitee}
                      />
                    )}
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    {checkUserCookie() &&
                      t(
                        cookies.get('userData')['description']['data']['role']
                      ) === 'Rehabilitowany' && <HulloPoints />}

                    {checkUserCookie() &&
                      cookies.get('userData')['description']['data']['role'] ===
                        'trainer' &&
                      rehabilitation.status === 1 && (
                        <div className={classes.deleteRehabBox}>
                          <Button onClick={handleDelete}>
                            Usuń rehabilitację
                          </Button>
                          {loading && (
                            <div className={classes.loaderWrapper}>
                              <BallTriangle
                                height="2.5rem"
                                width="2.5rem"
                                color="#27BDBE"
                                ariaLabel="loading-indicator"
                              />
                            </div>
                          )}
                        </div>
                      )}
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <TherapyDetails description={rehabilitation.long_desc} />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <div className={classes.scheduleTitleBox}>
                      <Typography variant={'h4'}>Harmonogram</Typography>
                      {checkUserCookie() &&
                        cookies.get('userData')['description']['data'][
                          'role'
                        ] === 'trainer' && (
                          <Button>
                            <Link
                              to={`/rehabilitacje/${rehabilitation.rehab_id}/nowy-harmonogram`}
                            >
                              Edytuj harmonogram
                            </Link>
                          </Button>
                        )}
                    </div>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <div className={classes.slider}>
                      {scheduleLoading && (
                        <div className={classes.loaderWrapper}>
                          <BallTriangle
                            heigth="70"
                            width="70"
                            color="#27BDBE"
                            ariaLabel="loading-indicator"
                          />
                        </div>
                      )}
                      {!scheduleLoading && (
                        <Slider {...settings}>
                          {rehabilitationSchedule.length > 0 &&
                            rehabilitationSchedule.map(
                              (schedule) =>
                                (schedule = (
                                  <TaskGroup
                                    id={schedule.id}
                                    date={schedule.date}
                                    exercises={schedule.exercises}
                                    rehabId={rehabilitation.rehab_id}
                                    setRefreshSchedule={setRefreshSchedule}
                                    setScheduleLoading={setScheduleLoading}
                                  />
                                ))
                            )}
                        </Slider>
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item lg={2} xs={12} />
                  <Grid item lg={8} xs={12}>
                    <Typography variant={'h4'}>Komentarze</Typography>
                    <Comments rehabId={rehabilitation.rehab_id} />
                  </Grid>
                  <Grid item lg={2} xs={12} />
                </Grid>
              </>
            )}
          </Container>
        </section>
      </main>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarType}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SingleRehabilitation;

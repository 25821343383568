import React from 'react';
import useStyles from './GameCard.style';
// import img from '../../../../assets/img/placeholders/exercise.jpg';
import Modal from '@mui/material/Modal';
import { Button, Container, Grid, Typography } from '@mui/material';
import closeModal from '../../../../assets/svg/close-modal.svg';

function GameCard({ popular, funFact, points = 0, title = '', description = '', unlocked = false,  }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={classes.gameCard}>
      {!funFact && (
        <div className="image-wrapper">
          <img alt="" src={'https://hullo.pl/wp-content/uploads/2022/06/MicrosoftTeams-image.png'} />
          <div className="mask"></div>
          {popular && (
            <div className="popular">
              <p>POPULARNE</p>
            </div>
          )}
        </div>
      )}
      <div className="text-wrapper">
        <Typography variant={'body2'}>{title}</Typography>
        <div className={classes.pointsWrapper}>
          <Typography variant={'h4'}>{points}</Typography>
          <Typography variant={'body1'}>Punktów</Typography>
        </div>
        { unlocked ? (
            <Button onClick={handleOpen}>Szczegóły</Button>
        ) : ''}
      </div>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.modalContent}>
          <button onClick={handleClose} className={classes.closeModal}>
            <img src={closeModal} alt="" />
          </button>
          <div className={classes.image}>
            <img alt="" src={'https://hullo.pl/wp-content/uploads/2022/06/MicrosoftTeams-image.png'} />
            {/*<div className="mask"></div>*/}
          </div>
          <div className={classes.text}>
            <Typography variant={'h6'}>{title}</Typography>
            <div className="unblock-game">
              <div className={classes.pointsWrapper}>
                <Typography variant={'h4'}>{points}</Typography>
                <Typography variant={'body1'}>Punktów</Typography>
              </div>
              {/*<Button>Odblokuj</Button>*/}
            </div>
            <Typography variant={'body2'}>{description}</Typography>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default GameCard;

import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '1.5rem',
    '& >p': {
      marginBottom: '1rem',
    },
    '& .comment': {
      backgroundColor: '#EFF2F3',
      borderRadius: '1.4rem',
      padding: '1.5rem 2rem',
      marginBottom: '1rem',
      '& span': {
        display: 'flex',
        color: '#8E979C',
      },
      '& div': {
        display: 'flex',
        marginBottom: '1rem',
        '& span': {
          color: '#68747B',
        },
        '& span:first-of-type': {
          color: '#27BDBE',
        },
      },
    },
  },
});

export default useStyles;

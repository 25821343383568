import React, { useEffect, useState } from 'react';
import useStyles from './ProgressStats.style';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import checkUserCookie from '../../../../api/checkUserCookie';
import SingleTherapy from '../SingleTherapy/SingleTherapy';
import AdminSingleTherapy from '../AdminSingleTherapy/AdminSingleTherapy';
import Cookies from 'universal-cookie';
import getRehabilitation from '../../../../api/getRehabilitation';
import { PieChart } from 'react-minimal-pie-chart';

function ProgressStats(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const { t } = useTranslation();

  const [rehabilitationCount, setRehabilitationCount] = useState(0);
  const [inactiveRehabilitationCount, setInactiveRehabilitationCount] =
    useState(0);

  const [loading, setLoading] = useState(true);

  const defaultLabelStyle = {
    fontSize: '5px',
    // fontFamily: 'sans-serif',
    fill: 'white',
  };

  useEffect(() => {
    if (checkUserCookie()) {
      getRehabilitation(
        `/rehabilitation/list`
        // `${cookies.get('userData')['description']['data']['id']}`,
        // `${cookies.get('userData')['description']['data']['role']}`,
        // `${cookies.get('userData')['description']['data']['token']}`
      ).then((result) => {
        setRehabilitationCount(result.length);
        setLoading(false);
        console.log(result);
      });
    }

    if (checkUserCookie()) {
      getRehabilitation(`/rehabilitation/list-inactive`).then((result) => {
        setInactiveRehabilitationCount(result.length);
        setLoading(false);
        console.log(result);
      });
    }
  }, []);

  if (!checkUserCookie()) {
    return (
      <div
        className={
          classes.root +
          ' ' +
          'component' +
          ' ' +
          'text-component' +
          ' ' +
          'progressbox-component'
        }
      >
        <div className={'component-inner'}>
          <Typography component={'h3'} variant={'h5'}>
            Progres rehabilitacji
          </Typography>
          <Typography component={'p'} variant={'caption'}>
            Zaloguj się aby sprawdzić swoje rehabilitacje
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        classes.root +
        ' ' +
        'component' +
        ' ' +
        'text-component' +
        ' ' +
        'progressbox-component'
      }
    >
      <div className={'component-inner'}>
        <Typography component={'h3'} variant={'h5'}>
          Progres rehabilitacji
        </Typography>
        <Typography component={'h4'} variant={'body1'}>
          Rehabilitacje do wykonania:
        </Typography>
        <Typography component={'p'} variant={'caption'}>
          Rehabilitacje, w których jesteś osobą rehabilitowaną
        </Typography>
        <Typography component={'h5'} variant={'h3'}>
          {checkUserCookie() &&
          cookies.get('userData')['description']['data']['role'] ===
            'rehabilitee'
            ? rehabilitationCount
            : '0'}
        </Typography>
        <Typography component={'h4'} variant={'body1'}>
          Rehabilitacje do nadzoru:
        </Typography>
        <Typography component={'p'} variant={'caption'}>
          Rehabilitacje, w których jesteś opiekunem
        </Typography>
        <Typography component={'h5'} variant={'h3'}>
          {checkUserCookie() &&
          cookies.get('userData')['description']['data']['role'] === 'guardian'
            ? rehabilitationCount
            : '0'}
        </Typography>
        <Typography component={'h4'} variant={'body1'}>
          Rehabilitacje do realizacji
        </Typography>
        <Typography component={'p'} variant={'caption'}>
          Rehabilitacje, w których jesteś trenerem
        </Typography>
        <Typography component={'h5'} variant={'h3'}>
          {checkUserCookie() &&
          cookies.get('userData')['description']['data']['role'] === 'trainer'
            ? rehabilitationCount
            : '0'}
        </Typography>

        <Typography component={'h4'} variant={'body1'}>
          Rehabilitacje niekatywne
        </Typography>
        <Typography component={'p'} variant={'caption'}>
          Rehabilitacje nieaktywne
        </Typography>
        <Typography component={'h5'} variant={'h3'}>
          {checkUserCookie() &&
          cookies.get('userData')['description']['data']['role'] === 'trainer'
            ? inactiveRehabilitationCount
            : '0'}
        </Typography>
        <Typography component={'h4'} variant={'body1'}>
          Twoje rehabilitacje
        </Typography>
        <PieChart
          labelStyle={{
            ...defaultLabelStyle,
          }}
          labelPosition={50}
          label={({ dataEntry }) => `${dataEntry.title} : ${dataEntry.value}`}
          data={[
            {
              label: 'Label One',
              title: 'Aktywne',
              value: rehabilitationCount,
              color: '#66C4E9',
            },
            {
              label: 'Label Two',
              title: `Zakończone`,
              value: inactiveRehabilitationCount,
              color: '#E14C63',
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ProgressStats;

import React from 'react';
import theme from '../../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    '& .MuiGrid-container': { marginTop: '0' },
    '& .MuiGrid-container:first-of-type': {
      '& .MuiGrid-item': {
        paddingTop: '0',
      },
    },
  },
  loaderWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  otherNews: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .wrapper': {
      width: 'calc((100% - 3rem) / 3)',
      // paddingRight: '1rem',
      '&:last-of-type': {
        // paddingRight: '0',
      },
      '& >div': {
        '& .image-wrapper': {
          width: '100%',
          paddingTop: '57.15%',
        },
        '& .text-wrapper': {
          marginTop: '0',
          width: '100%',
          left: '0',
          borderRadius: '0',
          boxShadow: 'unset',
          backgroundColor: 'unset',
          padding: '0.5rem 0 0 0 ',
          '& h6': {
            margin: '0.5rem 0',
            fontSize: '1.6rem',
          },
          '& p': {
            display: 'none',
          },
        },
      },
    },
    '@media(max-width:600px)': {
      flexWrap: 'wrap',
      '& .wrapper': {
        width: '100%',
        marginBottom: '2rem',
      },
    },
  },
  content: {
    '& img': {
      maxWidth: '100%',
      objectFit: 'contain',
    },
  },
});

export default useStyles;

import React, { useState } from 'react';
import useStyles from './PointsBox.style';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function PointsBox(props) {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <div className="points">
        <Typography variant={'h3'}>7381</Typography>
      </div>
      <div className="text">
        <Typography variant={'h5'}>Hullo punkty</Typography>
        <Button>Wykorzystaj</Button>
      </div>
    </div>
  );
}

export default PointsBox;

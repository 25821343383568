import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '1.5rem',
  },
});

export default useStyles;

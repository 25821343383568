import React from 'react';
import theme from '../../../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {},
  feedWrapper: {
    display: 'flex !important',
    flexDirection: 'row !important',
    width: '100% !important',
    height: 'auto !important',
    flexWrap: 'no-wrap !important',
    '@media(max-width:600px)': {
      flexWrap: 'wrap !important',
    },
  },
  singleNews: {
    width: 'calc(calc(100% / 3) - 1rem)',
    marginRight: '1.5rem',
    '&:nth-of-type(3n)': {
      marginRight: '0rem',
    },
    '& .content-wrapper': {
      '& span': {
        color: '#27BDBE',
      },
    },
    '& .image-wrapper': {
      width: '100%',
      minHeight: '10rem',
      marginBottom: '1rem',

      '& a': {
        display: 'block',
        position: 'relative',

        '& img': {
          width: '100%',
          maxWidth: '100%',
          height: 'auto',
          display: 'block',
          position: 'relative',
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
    '@media(max-width:600px)': {
      width: '100%',
      marginRight: '0',
    },
  },
});

export default useStyles;

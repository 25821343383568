import React from 'react';
import theme from "../../../../theme";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: 'block',

        '& .component-inner': {
            display: 'flex',
            flexDirection: 'column',
            padding: '2rem',
            background: theme.palette.background.paper,
            borderRadius: '1.4rem',

            '& .upper-wrapper': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',

                '& .circle-wrapper': {
                    position: 'relative',
                    display: 'block',
                    margin: 0,

                    '& .percentage-wrapper': {
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }
                },

                '& .label-wrapper': {
                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'auto',
                    margin: 'auto',
                    paddingLeft: '1rem',
                }
            }
        },
        '& svg': {
            '& circle': {
                stroke: theme.palette.primary.main,
            }
        }
    }
});

export default useStyles;
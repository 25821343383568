import {Helmet} from "react-helmet";
import React from "react";

function generateHelmet( title='%REACT_APP_NAME%', description='') {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>
    );
}

export { generateHelmet }
import Cookies from 'universal-cookie';

function createComment(commentContent = 'Domyślna treść komentarza', rehabId) {
  var axios = require('axios');

  const cookies = new Cookies();

  var data = JSON.stringify({
    rehabilitation: rehabId,
    author_name: `${
      cookies.get('userData')['description']['data']['displayName']
    }`,
    content: commentContent,
  });

  var config = {
    method: 'post',
    url:
      process.env.REACT_APP_API_URL +
      '/rehabilitation/' +
      rehabId +
      '/comments',
    headers: {
      'X-APP-USERID': `${cookies.get('userData')['description']['data']['id']}`,
      'X-APP-ROLE': `${cookies.get('userData')['description']['data']['role']}`,
      Authorization: `Bearer ${
        cookies.get('userData')['description']['data']['token']
      } `,

      'Content-Type': 'application/json',
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
}

export default createComment;

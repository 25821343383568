import React, {useEffect, useState} from 'react';
import useStyles from './HulloPoints.style';
import {useTranslation} from 'react-i18next';
import {Button, Grid, IconButton, Typography} from '@mui/material';
import checkUserCookie from "../../../api/checkUserCookie";
import Cookies from "universal-cookie";
import HulloApi from "../../../api/HulloApi";

function HulloPoints(props) {
    const classes = useStyles();
    const cookies = new Cookies();
    const {t} = useTranslation();

    const [pointsCount, setPointsCount] = useState(0);
    //const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (checkUserCookie()) {

            HulloApi.getPoints().then((response) => {
                console.log(response.data);
                setPointsCount(response.data);
                //setLoading(false);
            }).catch((error) => {
               console.log('Error - getPoints')
            });
        }
    }, []);

    if( ! checkUserCookie() ){
        return(
            <div
                className={
                    classes.root +
                    ' ' +
                    'component' +
                    ' ' +
                    'text-component' +
                    ' ' +
                    'progressbox-component'
                }
            >
                <div className={'component-inner'}>
                    <Typography component={'h3'} variant={'h5'}>
                        Hullo punkty
                    </Typography>
                    <Typography component={'p'} variant={'caption'}>
                        Zaloguj się aby sprawdzić swoje punkty
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <div
            className={
                classes.root +
                ' ' +
                'component' +
                ' ' +
                'text-component' +
                ' ' +
                'hullopoints-component' +
                ' ' + classes.box
            }
        >
            <div className={'component-inner points-inner'}>
                <Typography className={'points-count'} variant={'h3'}>{pointsCount}</Typography>
                <div>
                    <Typography className={'sub-title'} variant={'h5'}>Hullo punkty</Typography>
                </div>
            </div>
        </div>
    );
}

export default HulloPoints;

import React, { useState } from 'react';
import useStyles from './SingleTherapy.style';
import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';

function SingleTherapy({
  rehabilitationID,
  rehabilitationTitle,
  slug,
  role = 'Rola',
  name = 'Imię i nazwisko',
  status = 'Status',
  createdDate,
  updatedDate,
}) {
  const classes = useStyles();
  const stroke = 4;
  const radius = 50;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progress = 0.739929;
  return (
    <Link to={slug}>
      <div className={classes.box}>
        <Typography variant={'caption'}>ID: {rehabilitationID}</Typography>
        <Typography variant={'caption'}>
          <br /> Tytuł: {rehabilitationTitle}
        </Typography>
        {/* tymczasowo wyciągnięte ID, do usunięcia */}

        <div className="upper-row">
          {/* <div className={'circle-wrapper'}>
            <svg height={radius * 2} width={radius * 2}>
              <circle
                strokeDasharray={
                  progress * circumference + ' ' + progress * circumference
                }
                style={'stroke-dashoffset: ' + circumference}
                stroke-width={stroke}
                fill="transparent"
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                style={{
                  transition: 'stroke-dashoffset 0.35s',
                  transform: 'rotate(-90deg)',
                  transformOrigin: '50% 50%',
                }}
              />
            </svg>
            <div className={'percentage-wrapper'}>
              <Typography component={'p'} variant={'body1'}>
                {Math.floor(progress * 100)}%
              </Typography>
            </div>
          </div> */}

          <div>
            <Typography variant={'caption'}>{role}</Typography>
            <Typography variant={'body1'}>{name}</Typography>
          </div>
        </div>
        {/* <div className="lower-row">
          <div>
            <Typography variant={'h4'}>6</Typography>
            <Typography variant={'caption'}>Ćwiczeń do wykonania</Typography>
          </div>
          <div>
            <Typography variant={'h4'}>45</Typography>
            <Typography variant={'caption'}>Komentarzy</Typography>
          </div>
        </div> */}
        <div className="dates-row">
          <Typography variant={'caption'}>
            Status: <b>{status}</b>
          </Typography>
          <Typography variant={'caption'}>
            Data utworzenia: <b>{createdDate}</b>
          </Typography>
          <Typography variant={'caption'}>
            Data aktualizacji: <b>{updatedDate}</b>
          </Typography>
        </div>
      </div>
    </Link>
  );
}

export default SingleTherapy;

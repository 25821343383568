import React, { useState, useEffect } from 'react';
import useStyles from './Login.style';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import Header from '../../components/General/Header/Header';
import { generateHelmet } from '../../utils/helperFunctions';
import Footer from '../../components/General/Footer/Footer';
import TextField from '@mui/material/TextField';
import createAccountIcon from '../../assets/svg/create-account.svg';
import getUser from '../../api/getUser';
import Snackbar from '@mui/material/Snackbar';
import checkUserCookie from '../../api/checkUserCookie';
import Cookies from 'universal-cookie';

function Login() {
  const classes = useStyles();
  const [userMail, setUserMail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const [openValidSnackbar, setOpenValidSnackbar] = useState(false);
  const [openInvalidSnackbar, setOpenInvalidSnackbar] = useState(false);

  const [loadingUserData, setLoadingUserData] = useState(true);

  const handleUserMail = (event) => {
    setUserMail(event.target.value);
  };

  const handleUserPassword = (event) => {
    setUserPassword(event.target.value);
  };

  const cookies = new Cookies();

  useEffect(() => {
    if (!loadingUserData) {
      if (checkUserCookie()) {
        setOpenValidSnackbar(true);
      } else {
        setOpenInvalidSnackbar(true);
      }
    }
  }, [loadingUserData]);

  const handleLogin = (event) => {
    getUser(userMail, userPassword, setLoadingUserData);
  };

  const handleValidSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenValidSnackbar(false);
  };

  const handleInvalidSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenInvalidSnackbar(false);
  };

  const action = <React.Fragment></React.Fragment>;

  return (
    <>
      {generateHelmet('Zaloguj się', 'Logowanie do aplikacji hullo')}
      <Header />
      <main>
        <section className={classes.loginSection}>
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <div className="left-col">
                  <Typography variant={'h3'}>
                    Rodzinne Centrum Integracyjne Hullo
                  </Typography>
                </div>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography variant={'h4'}>Logowanie</Typography>
                <div className="login-box">
                  <div className={classes.input + ' ' + ''}>
                    <label>Adres email</label>
                    <TextField
                      placeholder="Adres email"
                      fullWidth
                      variant="outlined"
                      value={userMail}
                      onChange={handleUserMail}
                    />
                  </div>
                  <div className={classes.input + ' ' + ''}>
                    <label>Hasło</label>
                    <TextField
                      type="password"
                      placeholder="Hasło"
                      fullWidth
                      variant="outlined"
                      // type="password"
                      value={userPassword}
                      onChange={handleUserPassword}
                    />
                  </div>
                  <Button onClick={handleLogin}>Zaloguj się</Button>
                  <Snackbar
                    open={openValidSnackbar}
                    autoHideDuration={4000}
                    onClose={handleValidSnackbarClose}
                    message={`Zalogowano poprawnie`}
                    action={action}
                    className={'success'}
                  />
                  <Snackbar
                    open={openInvalidSnackbar}
                    autoHideDuration={4000}
                    onClose={handleInvalidSnackbarClose}
                    message={`Błędne dane logowania`}
                    action={action}
                    className={'error'}
                  />
                </div>
                <div className="login-box">
                  <Typography variant={'h5'}>Nie pamiętasz hasła? </Typography>
                  <Typography variant={'subtitle2'}>
                    Przypomnij swoje hasło
                  </Typography>
                  <Button>
                    <a href="https://hullo.pl/przypomnij-haslo/">Przypomnij</a>
                  </Button>
                </div>
                <div className="login-box">
                  <img src={createAccountIcon} alt="" />
                  <Typography variant={'h5'}>Nie posiadasz konta?</Typography>
                  <Typography variant={'subtitle2'}>
                    Stwórz nowe za darmo
                  </Typography>
                  <Button>
                    <a href="https://hullo.pl/zarejestruj-sie/">Stwórz konto</a>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Login;

import React, { useState } from 'react';
import useStyles from './PointsHistoryBox.style';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function PointsHistoryBox({ button }) {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <div className="points">
        <Typography variant={'h5'}>+23</Typography>
      </div>
      <div className="date">
        <Typography variant={'caption'}>24.01.22</Typography>
        <Typography variant={'h6'}>15:34</Typography>
      </div>
      <div className="comment">
        <Typography variant={'caption'}>
          Dzień dobry, mam pytanie Lorem ipsum dolor sit amet, consectetur
          adipiscing elit.
        </Typography>
      </div>
      <div className="coach">
        <Typography variant={'subtitle1'}>Albert Nowak</Typography>
        <p>Trener</p>
      </div>
    </div>
  );
}

export default PointsHistoryBox;

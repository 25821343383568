import Cookies from 'universal-cookie';
import RehabilitationIndex from '../views/Modules/Rehabilitation/RehabilitationIndex';

function createRehabilitation(
  rehabTitle,
  rehabiliteeId,
  guardianId,
  rehabShortDesc,
  rehabLongDesc
) {
  var axios = require('axios');

  const cookies = new Cookies();

  var data = JSON.stringify({
    name: rehabTitle,
    rehabilitee: rehabiliteeId,
    guardian: guardianId,
    short_desc: rehabShortDesc,
    long_desc: rehabLongDesc,
    schedule: [],
  });

  var config = {
    method: 'post',
    url: process.env.REACT_APP_API_URL + '/rehabilitation/create',

    headers: {
      'X-APP-USERID': `${cookies.get('userData')['description']['data']['id']}`,
      'X-APP-ROLE': `${cookies.get('userData')['description']['data']['role']}`,
      Authorization: `Bearer ${
        cookies.get('userData')['description']['data']['token']
      } `,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  let rehabilitationID;
  axios(config)
    .then(function (response) {
      rehabilitationID = response.data.rehab_id;
      // window.location.href = `/rehabilitacje/${response.data.rehab_id}`;
      console.log(response.data.rehab_id);
      //   console.log('DATA status code:' + JSON.stringify(response.data.code));
      //   cookies.set('userData', response.data, { path: '/' });
      //   cookies.set('responseStatus', response.data.code, { path: '/' });
      //   handleLoading(false);
    })
    .catch(function (error) {
      console.log(error);
      //   cookies.set('responseStatus', 'error', { path: '/' });
      //   handleLoading(false);
    });
}

export default createRehabilitation;

import React, { useEffect, useState } from 'react';
import useStyles from './CoachBox.style';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import getUserName from '../../../../api/getUserName';

function CoachBox({ showContact, userID = '0', role = 'Rola' }) {
  const classes = useStyles();
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (userID !== '0') {
      getUserName(`${userID}`).then((result) => {
        setUserName(result.name);
      });
    }
  }, []);

  return (
    <div className={classes.box}>
      <Typography variant={'body1'}>{role}</Typography>
      <Typography variant={'h5'}>{userName}</Typography>
      {showContact && (
        <>
          <Typography variant={'body1'}>Kontakt</Typography>
          <Button>
            <a href="tel:+48123123123">+48 123 123 123</a>
          </Button>
        </>
      )}
    </div>
  );
}

export default CoachBox;

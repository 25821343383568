import Cookies from 'universal-cookie';

function checkUserCookie() {
  const cookies = new Cookies();

  if (cookies.get('responseStatus') == 200) {
    return true;
  } else {
    return false;
  }
}

export default checkUserCookie;

import React, { useState, useEffect } from 'react';
import useStyles from './NewsCard.style';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';
import placeholder from '../../../../assets/img/placeholders/news.png';

function NewsCard({ title, key, slug, date, excerpt, imageUrl }) {
  const classes = useStyles();

  return (
    <div className={classes.article}>
      <div className="image-wrapper">
        <Link to={slug}>
          <img alt="" src={imageUrl.length ? imageUrl : placeholder} />
          {/* <div className="mask"></div> */}
        </Link>
      </div>
      <div className="text-wrapper">
        <Typography variant={'caption'}>{date}</Typography>

        <Typography variant={'h6'}>{title}</Typography>

        <Typography
          variant={'body2'}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></Typography>
        <Link to={slug}>
          <Button variant={'outlined'}>Czytaj więcej</Button>
        </Link>
      </div>
    </div>
  );
}

export default NewsCard;

import React, { useState } from 'react';
import useStyles from './NewsIntro.style';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';
import placeholder from '../../../../assets/img/placeholders/news.png';

function NewsIntro({ title, date, image }) {
  const classes = useStyles();

  return (
    <div className={classes.introBox}>
      <div className="text-wrapper">
        <div>
          <Typography variant={'h4'}>{title}</Typography>
          <Typography variant={'body1'}>{date}</Typography>
        </div>
        <div className="share-wrapper">
          <Typography variant={'body2'}>Udostępnij!</Typography>
          <div>
            <a
              href={
                'https://twitter.com/intent/tweet?text=' + window.location.href
              }
            ></a>
            <a
              href={
                'https://www.facebook.com/sharer/sharer.php?u=' +
                window.location.href
              }
            ></a>
          </div>
        </div>
      </div>
      <div className="image-wrapper">
        <div className="wrapper">
          <img alt="" src={image.length ? image : placeholder} />
          {/* <div className="mask"></div> */}
        </div>
      </div>
    </div>
  );
}

export default NewsIntro;

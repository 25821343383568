import React from 'react';
import useStyles from './Bundles.style';
import { Container, Grid, Typography } from '@mui/material';
import { generateHelmet } from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import Footer from '../../../components/General/Footer/Footer';

function BundlesIndex() {
  const classes = useStyles();
  return (
    <>
      {generateHelmet('Pakiety', 'Moduł pakietów aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <BreadcrumbsComponent child="Pakiety" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <Typography variant={'h3'}>Pakiety</Typography>
                <Typography variant={'h6'}>Pakiety atrakcji są teraz dostępne na stronie <a href={process.env.REACT_APP_WWW_URL} style={{textDecoration: 'underline'}}>hullo.pl</a></Typography>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default BundlesIndex;

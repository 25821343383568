import React from 'react';
import theme from '../../../../theme';
import { createUseStyles } from 'react-jss';
import twitterIcon from '../../../../assets/svg/icons/twitter.svg';
import facebookIcon from '../../../../assets/svg/icons/facebook.svg';
import linkedinIcon from '../../../../assets/svg/icons/linkedin.svg';

const useStyles = createUseStyles({
  introBox: {
    display: 'flex',
    padding: '0 1rem',
    alignItems: 'center',
    margin: '4rm 0 6rem 0',
    '& .text-wrapper': {
      minHeight: '30rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '50%',
      backgroundColor: 'white',
      boxShadow: '0px 12px 10px rgb(0 0 0 / 5%)',
      borderRadius: '1.4rem 0 0 1.4rem',
      padding: '3rem 5rem',
      ' & h4~p': {
        fontWeight: '600',
        color: '#27bdbe',
      },
      '& .share-wrapper': {
        marginTop: '4rem',
        '& div': {
          marginTop: '1rem',
          backgroundColor: '#eff2f3',
          borderRadius: '4.7rem',
          padding: '0.5rem',
          display: 'inline-flex',
          '& a': {
            display: 'inline-flex',
            width: '4rem',
            height: '4rem',
            borderRadius: '100%',
            backgroundColor: 'transparent',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 250ms ease',

            '&:hover': {
              backgroundColor: 'white',
            },
            '&:before': {
              content: ' ""',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              width: '1.8rem',
              height: '1.6rem',
              backgroundImage: `url(${twitterIcon})`,
            },
            '&:nth-of-type(2)': {
              margin: '0 0.5rem',
              '&:before': {
                backgroundImage: `url(${facebookIcon})`,
              },
            },

            '&:nth-of-type(3)': {
              margin: '0 0.5rem',
              '&:before': {
                backgroundImage: `url(${linkedinIcon})`,
              },
            },
          },
        },
      },
    },
    '& .image-wrapper': {
      width: '50%',
      '& .wrapper': {
        borderRadius: '1.4rem',
        position: 'relative',
        paddingTop: '57.15%',
        overflow: 'hidden',
        '& img': {
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          objectFit: 'cover',
          height: '100%',
        },
        // '& .mask': {
        //   position: 'absolute',
        //   top: '5%',
        //   left: '-10%',
        //   width: '120%',
        //   height: '110%',
        //   background:
        //     'linear-gradient(360deg, #27bdbe 7.36%, rgba(39, 183, 190, 0) 88.57%)',
        //   transform: 'matrix(0.99, -0.07, 0.12, 1, 0, 0)',
        // },
      },
    },
    '@media(max-width:1200px)': {
      flexDirection: 'column-reverse',
      '& .text-wrapper': {
        width: '100%',
        borderRadius: '0 0 1.4rem 1.4rem',
        minHeight: 'unset',
      },
      '& .image-wrapper': {
        width: '100%',
        '& .wrapper': {
          borderRadius: '1.4rem 1.4rem 0 0 !important',
        },
      },
    },
    '@media(max-width:600px)': {
      flexDirection: 'column-reverse',
      '& .text-wrapper': {
        padding: '1rem',
      },
    },
  },
});

export default useStyles;

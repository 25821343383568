import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  box: {
    padding: '2rem',
    borderRadius: '1.4rem',
    backgroundColor: 'white',
  },
  buttonsWrapper: {
    marginBottom: '1.5rem',
    background: '#D9DCDE',
    borderRadius: '4.8rem',
    padding: '1rem',
    display: 'inline-flex',
    '& button': {
      borderRadius: '5.2rem',
      border: 'none',
      padding: '1.4rem 2.8rem',
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '1.4rem',
      color: '#42515a',
      backgroundColor: 'transparent',
      '&:first-of-type': {
        marginRight: '1rem',
      },
      '&.active': {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 12px 25px rgba(0, 0, 0, 0.1)',
        color: '#27BDBE',
      },
      cursor: 'pointer',
    },
  },
  input: {
    marginBottom: '1.5rem',
    '& label': {
      fontWeight: '600',
      color: '#42515A',
      marginBottom: '0.5rem',
      display: 'block',
    },
    '&.file-input': {},
  },
});

export default useStyles;

import React, { useState } from 'react';
import useStyles from './TherapyDetails.style';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import icon from '../../../../assets/svg/therapy-details.svg';

function TherapyDetails({ description }) {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <div>
        <img src={icon} alt="" />
        <Typography variant={'subtitle1'}>Szczegóły terapii</Typography>
      </div>
      <Typography variant={'subtitle1'}>{description}</Typography>
    </div>
  );
}

export default TherapyDetails;

import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '2rem 3rem',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
      '& h6': {
        color: '#42515A',
        marginLeft: '1rem',
      },
    },
    '& h6': {
      color: '#68747B',
    },
  },
});

export default useStyles;

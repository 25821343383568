import Cookies from 'universal-cookie';
import getSchedule from './getSchedule';
import { v4 as uuidv4 } from 'uuid';

function createSchedule(
  rehabId,
  scheduleDate = '2022-06-13',
  excerises = [
    {
      id: 0,
      name: 'API',
      description: 'Lorem ipsum 2',
      duration: '15 min',
      status: 1,
    },
  ]
) {
  var axios = require('axios');
  const cookies = new Cookies();

  getSchedule(rehabId).then((result) => {
    var data = JSON.stringify([
      {
        id: uuidv4(),
        date: scheduleDate,
        exercises: excerises,
      },

      ...result,
    ]);
    console.log(data);
    console.log(result);
    var config = {
      method: 'put',
      url:
        process.env.REACT_APP_API_URL +
        '/rehabilitation/' +
        rehabId +
        '/schedule',

      headers: {
        'X-APP-USERID': `${
          cookies.get('userData')['description']['data']['id']
        }`,
        'X-APP-ROLE': `${
          cookies.get('userData')['description']['data']['role']
        }`,
        Authorization: `Bearer ${
          cookies.get('userData')['description']['data']['token']
        } `,

        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  });
}

export default createSchedule;

import React, {useState, useEffect} from 'react';
import useStyles from './Rehabilitation.style';
import {Alert, Button, Container, Grid, Typography} from '@mui/material';
import {generateHelmet} from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import Footer from '../../../components/General/Footer/Footer';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import { Navigate } from "react-router-dom";
import {BallTriangle} from 'react-loader-spinner';
import Cookies from 'universal-cookie';
import {useTranslation} from 'react-i18next';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import getUserName from '../../../api/getUserName';
import HulloApi from "../../../api/HulloApi";

function NewRehabilitation() {
    const classes = useStyles();
    const cookies = new Cookies();
    const {t} = useTranslation();

    const [loading, setLoading] = useState(true);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const [formValid, setFormValid] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        rehabilitee:  '',
        guardian: '',
        shortDesc: '',
        longDesc: '',
    });

    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');

    const updateValues = ({target: {name, value}}) => {
        setFormData({...formData, [name]: value});
    };

    const handleCreateNewRehab = () => {
        setLoading(true);
        HulloApi.createRehabilitation(
            formData.name, formData.rehabilitee, formData.guardian, formData.shortDesc, formData.longDesc
        ).then((response) => {
            console.log(response.data);

            setSnackbarOpen(true);
            setSnackbarMessage('Rehabilitacja dodana');
            setSnackbarType('success');

            setRedirectUrl('/rehabilitacje/' + String(response.data.rehab_id));
            setRedirect(true);
            setLoading(false);
        }).catch((error) => {
            setSnackbarOpen(true);
            setSnackbarMessage('W trakcie dodawania rehabilitacji wystąpił błąd. Sprawdź poprawność danych lub spróbuj ponownie później.');
            setSnackbarType('error');
            console.log(error.data);
            setLoading(false);
        });
    };

    function validateForm() {
        let errorKeys = [];
        for (let key in formData){
            if( formData[key] === '' ){
                errorKeys.push(key);
            }
        }
        if(errorKeys.length == 0){
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }

    useEffect(() => {
        validateForm();
    }, [formData]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const action = <React.Fragment></React.Fragment>;

    const [users, setUsers] = useState('');
    useEffect(() => {
        setLoading(false);
        getUserName(`?_fields=acf,id,name`).then((result) => {
            setUsers(result);
            // setLoading(false);
            console.log(result);
        });
    }, []);

    if (redirect) {
        return <Navigate to={redirectUrl} />;
    }

    return (
        <>
            {generateHelmet('Rehabilitacje', 'Moduł rehabilitacyjny aplikacji hullo')}
            <Header/>
            <main>
                <section>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <BreadcrumbsComponent
                                    parent="Rehabilitacje"
                                    parentLink="/rehabilitacje"
                                    child="Dodaj nową rehabilitację"
                                />
                            </Grid>{' '}
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Typography variant={'h4'}>Dodaj nową rehabilitację</Typography>

                                <div className={classes.newRehabilitationBox}>
                                    <div className={classes.input + ' ' + ''}>
                                        <label>Tytuł rehabilitacji</label>
                                        <TextField
                                            name={'name'}
                                            placeholder="Tytuł rehabilitacji"
                                            fullWidth
                                            variant="outlined"
                                            onChange={updateValues}
                                        />
                                    </div>
                                    <div className={classes.input + ' ' + ''}>
                                        <label>Rehabilitowany</label>
                                        <TextField
                                            name={'rehabilitee'}
                                            placeholder="Rehabilitowany"
                                            fullWidth
                                            variant="outlined"
                                            onChange={updateValues}
                                        />
                                    </div>

                                    {/*<div className={classes.input}>
                                        {' '}
                                        <label>Rehabilitowany</label>
                                        <Select
                                            displayEmpty
                                            fullWidth
                                            value={newRehabRehabilitee}
                                            onChange={handleNewRehabRehabilitee}
                                            className={classes.customSelect}
                                        >
                                            <MenuItem value={''} disabled selected>
                                                Rehabilitowany
                                            </MenuItem>
                                            {!loading &&
                                            users
                                                .filter((user) => user.acf.approle === 'rehabilitee')
                                                .map(
                                                    (user) =>
                                                        (user = (
                                                            <MenuItem value={user.id}>{user.name}</MenuItem>
                                                        ))
                                                )}
                                        </Select>{' '}
                                    </div>*/}

                                    <div className={classes.input + ' ' + ''}>
                                        <label>Opiekun</label>
                                        <TextField
                                            name={'guardian'}
                                            placeholder="Opiekun"
                                            fullWidth
                                            variant="outlined"
                                            onChange={updateValues}
                                        />
                                    </div>
                                    <div className={classes.input + ' ' + ''}>
                                        <label>Krótki opis</label>
                                        <TextField
                                            name={'shortDesc'}
                                            placeholder="Opis"
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                            rows={4}
                                            onChange={updateValues}
                                        />
                                    </div>

                                    <div className={classes.input + ' ' + ''}>
                                        <label>Długi opis</label>
                                        <TextField
                                            name={'longDesc'}
                                            placeholder="Opis"
                                            fullWidth
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            onChange={updateValues}
                                        />
                                    </div>
                                    <Button
                                        onClick={handleCreateNewRehab}
                                        disabled={!formValid}
                                    >
                                        Dodaj rehabilitację
                                    </Button>
                                    {loading && (
                                        <div className={classes.loaderWrapper}>
                                            <BallTriangle
                                                height="2.5rem"
                                                width="2.5rem"
                                                color="#27BDBE"
                                                ariaLabel="loading-indicator"
                                            />
                                        </div>
                                    )}
                                </div>
                            </Grid>

                            {/*<Grid item lg={5} xs={12}>
                                <Typography variant={'h5'}>Inne rehabilitacje</Typography>
                                <Typography variant={'h5'}>All users from API</Typography>
                                {!loading &&
                                users
                                    .filter((user) => user.acf.approle === 'rehabilitee')
                                    .map(
                                        (user) =>
                                            (user = (
                                                <div>
                                                    <p>ID: {user.id}</p>
                                                    <p>NAME: {user.name}</p>
                                                    <p>ROLE: {user.acf.approle} </p>
                                                    <hr/>
                                                </div>
                                            ))
                                    )}
                            </Grid>*/}
                        </Grid>
                    </Container>
                </section>
            </main>
            <Footer/>
            <Snackbar open={snackbarOpen} autoHideDuration={8000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarType} sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default NewRehabilitation;

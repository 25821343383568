import React from 'react';
import theme from '../../../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  categoryWrapper: {
    backgroundColor: 'white',
    boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '1.4rem',
    padding: '2rem 4rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',

    '& h6': {
      color: '#27BDBE',
      marginRight: '2.5rem',
    },
    '& div': {
      display: 'flex',
      // maxWidth: '60rem',
      // width: '60rem',
      overflowX: 'auto',
      padding: '1rem 0',
      '& button': {
        marginRight: '1rem',
        minWidth: 'auto',
        '&.active': {
          borderColor: '#009ddb',
        },
      },
      '&::-webkit-scrollbar': {
        height: '5px',
      },

      /* Track */
      '&::-webkit-scrollbar-track': {
        // boxShadow: 'inset 0 0 5px grey',
        backgroundColor: '#EFF2F3',
        borderRadius: '7rem',
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#71838D',
        borderRadius: '7rem',
      },
    },
    '@media(max-width:600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '1.5rem',
      '& div': {
        maxWidth: '100%',
      },
    },
  },
});

export default useStyles;

import React from 'react';
import theme from '../../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    paddingBottom: '3rem',
  },
  footerContainer: {
    background: theme.palette.background.paper,
    borderRadius: '2rem' + ' !important',
    padding: '2rem' + ' !important',
    boxShadow: theme.shadows[1] + ' !important',
  },

  upperFooter: {
    width: '100% !important',
    margin: 0 + ' !important',
    padding: '0 2rem' + ' !important',
    paddingBottom: '2rem' + ' !important',
    //display: 'flex',
    //flexDirection: 'row',
    //alignItems: 'unset' + ' !important',
  },
  lowerFooter: {
    width: '100% !important',
    margin: 0 + ' !important',
    padding: '0 2rem' + ' !important',
    paddingBottom: '2rem' + ' !important',
  },
  footerItem: {
    padding: 0 + ' !important',
    '& .MuiButton-root': {
      backgroundColor: 'transparent',
      border: '2px solid white',
      color: '#42515A',
      marginTop: '1rem',
      '&:hover': {
        backgroundColor: 'white',
        border: '2px solid white',
        color: '#42515A',
      },
      '& a': { textDecoration: 'none' },
    },
    '& img': {
      marginBottom: '1rem',
    },
  },
  openingHoursItem: {
    padding: '1rem' + ' !important',
    '& .component': {
      height: '100%',
      '& .component-inner': {
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        padding: '2rem',
        backgroundColor: '#D4F2F2',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& h6': {
          color: '#27BDBE',
        },
        '& p': {
          display: 'inline-flex',
          justifyContent: 'space-between',
          marginTop: '1rem',
          '& span:first-of-type': {
            color: '#27BDBE',
            width: '10rem',
          },
        },
      },
    },
  },
  faqItem: {
    padding: '1rem' + ' !important',

    '& .component': {
      height: '100%',
      '& .component-inner': {
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        padding: '2rem',
        backgroundColor: '#CCEBF8',
      },
    },
  },
  contactItem: {
    padding: '1rem' + ' !important',

    '& .component': {
      height: '100%',
      '& .component-inner': {
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        padding: '2rem',
        backgroundColor: '#F8D2D8',
      },
    },
  },
  fundingItem: {
    display: 'block',
    padding: '2rem !important',
    border: 'solid 2px ' + theme.palette.grey['50'],
    borderRadius: theme.shape.borderRadius,

    '& img': {
      marginBottom: '2rem',
      maxWidth: '100%',
    },
  },
  additionalFooter: {
    width: '100% !important',
    margin: 0 + ' !important',
    padding: '0 2rem' + ' !important',
  },
  upperFooterContainer: {
    paddingTop: '3rem',
    // border: '1px solid red',
    '& .MuiButton-root': {
      border: '2px solid rgba(66, 81, 90, 0.2) ',
      marginTop: '0',
      '&:hover': {
        border: '2px solid rgba(66, 81, 90, 0.2) ',
      },
    },
    '& .MuiGrid-item': {
      display: 'flex',
      alignItems: 'center',
      '&:nth-of-type(2)': {
        '& button:nth-of-type(1)': {
          marginRight: '2rem',
        },
      },
      '&:nth-of-type(3)': {
        justifyContent: 'flex-end',
        '& a': {
          color: '#27BDBE',
        },
      },
      '@media(max-width:900px)': {
        justifyContent: 'center',
        '&:nth-of-type(2)': {
          margin: '2rem 0',
        },
        '&:nth-of-type(3)': {
          justifyContent: 'center',
        },
      },
      '@media(max-width:600px)': {
        justifyContent: 'center',
        '&:nth-of-type(2)': {
          flexDirection: 'column',
          '& button:nth-of-type(1)': {
            marginRight: '0 !important',
            marginBottom: '1rem',
          },
        },
      },
    },
  },
});

export default useStyles;

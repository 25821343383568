import React from 'react';
import theme from '../../theme';
import {createUseStyles} from 'react-jss';
import mainInfo from '../../assets/svg/account-main-info.svg';
import points from '../../assets/svg/account-points.svg';
import faq from '../../assets/svg/account-faq.svg';

const useStyles = createUseStyles({
    navigationButtons: {
        display: 'flex',
        flexDirection: 'column',
        '& button': {
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: '1.4rem',
            marginBottom: '1rem',
            fontSize: '1.8rem',
            fontWeight: '600',
            fontFamily: '"Montserrat", sans-serif',
            color: '#42515A',
            padding: '1.5rem 2rem',
            cursor: 'pointer',
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
            //   justifyContent: 'center',
            '&:before': {
                content: ' ""',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                width: '1.9rem',
                height: '2.4rem',
                marginRight: '1rem',
                backgroundImage: `url(${mainInfo})`,
            },
            '&.active': {
                backgroundColor: 'white',
            },
            '&.points-history-btn': {
                '&:before': {
                    backgroundImage: `url(${points})`,
                },
            },
            '&.faq-btn': {
                '&:before': {
                    backgroundImage: `url(${faq})`,
                },
            },
        },
    },
    contentWrapper: {
        backgroundColor: 'white',
        borderRadius: '1.4rem',
        padding: '2rem',

        display: 'none',
        transition: 'all 250ms ease',
        '&.active': {
            display: 'block',
        },
    },
    editData: {
        '& >div': {
            display: 'flex',
            flexWrap: 'wrap',
        },
    },
    input: {
        paddingRight: '3rem',
        marginBottom: '1rem',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& label': {
            marginBottom: '0.5rem',
        },
    },
    pointsTable: {
        marginTop: '2rem',
        '& .labels': {
            display: 'flex',

            '& div': {
                display: 'flex',
                alignItems: 'center',
                '&.points': {
                    width: 'calc(1 / 7 * 100%)',
                    paddingRight: '1rem',
                },
                '&.date': {
                    width: 'calc(1 / 7 * 100%)',
                },
                '&.comment': {
                    width: 'calc(3 / 7 * 100%)',
                    padding: '0 1rem',
                },
                '&.coach': {
                    width: 'calc(2 / 7 * 100%)',
                },
            },
        },
    },
});

export default useStyles;

import React from 'react';
import { createUseStyles } from 'react-jss';
import home from '../../../assets/svg/home.svg';
import separator from '../../../assets/svg/breadcrumb-separator.svg';

const useStyles = createUseStyles({
  breadcrumbsWrapper: {
    '& li': {
      color: '#42515A',
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: '700',
      '&:first-of-type': {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:before': {
          content: ' ""',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '1.5rem',
          height: '1.5rem',
          marginRight: '1rem',
          backgroundImage: `url(${home})`,
        },
      },
      '&.MuiBreadcrumbs-separator': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'transparent',
        marginRight: '4px',
        '&:before': {
          content: ' ""',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '6px',
          height: '10px',
          backgroundImage: `url(${separator})`,
        },
      },
    },
    '& a': {
      textDecoration: 'none',
      transition: 'all 250ms ease',
      '&:hover': {
        color: '#27BDBE',
      },
    },
    '& p': {
      margin: '0 !important',
    },
  },
});

export default useStyles;

import React, { useState } from 'react';
import useStyles from './BreadcrumbsComponent.style';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function BreadcrumbsComponent({
  parent,
  parentLink,
  parent1,
  parentLink1,
  child,
}) {
  const classes = useStyles();

  const { t } = useTranslation();

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  return (
    <div
      role="presentation"
      onClick={handleClick}
      className={classes.breadcrumbsWrapper}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">Start</Link>
        {parent && <Link to={parentLink}>{parent}</Link>}
        {parent1 && <Link to={parentLink1}>{parent1}</Link>}

        <p>{child}</p>
      </Breadcrumbs>
    </div>
  );
}

export default BreadcrumbsComponent;

import React from 'react';
import useStyles from "./Static.style";
import {
    Container,
    Grid,
    Typography
} from "@mui/material";
import {generateHelmet} from "../../utils/helperFunctions";
import Header from "../../components/General/Header/Header";

function NotFound() {
    const classes = useStyles();
    return (
        <>
            { generateHelmet('404', 'aplikacja hullo') }
            <Header/>
            <main>
                <section>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Typography component="h1">
                                    NotFound
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </section>
            </main>
        </>
    );
}

export default NotFound;
import Cookies from 'universal-cookie';
import getSchedule from './getSchedule';
import { v4 as uuidv4 } from 'uuid';
import { Schedule } from '@mui/icons-material';

function updateTaskStatus(rehabId, scheduleId, excerises, setFunc) {
  var axios = require('axios');
  const cookies = new Cookies();

  getSchedule(rehabId).then((result) => {
    //searching for exact task to update

    result.forEach((schedule) => {
      if (schedule.id === scheduleId) {
        schedule.exercises = excerises;
      }
    });

    var data = JSON.stringify([...result]);
    // console.log(data);
    // console.log(result);

    var config = {
      method: 'put',
      url:
        process.env.REACT_APP_API_URL +
        '/rehabilitation/' +
        rehabId +
        '/schedule',

      headers: {
        'X-APP-USERID': `${
          cookies.get('userData')['description']['data']['id']
        }`,
        'X-APP-ROLE': `${
          cookies.get('userData')['description']['data']['role']
        }`,
        Authorization: `Bearer ${
          cookies.get('userData')['description']['data']['token']
        } `,

        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setFunc(true);
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  });
}

export default updateTaskStatus;

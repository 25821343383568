import React, { useState, useEffect } from 'react';
import useStyles from './Comments.style';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import editBtn from '../../../../assets/svg/edit-btn.svg';
import removeBtn from '../../../../assets/svg/remove-btn.svg';
import SingleComment from '../SingleComment/SingleComment';
import { styled } from '@mui/material/styles';
import createComment from '../../../../api/createComment';
import { BallTriangle } from 'react-loader-spinner';
import getComments from '../../../../api/getComments';
import checkUserCookie from '../../../../api/checkUserCookie';
import Cookies from 'universal-cookie';

function Comments({ rehabId }) {
  const classes = useStyles();
  const cookies = new Cookies();

  const [commentContent, setCommentContent] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [commentsList, setCommentsList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refreshComments, setRefreshComments] = useState(true);

  const handleCommentContent = (event) => {
    setCommentContent(event.target.value);
  };

  const handleCreateComment = (event) => {
    createComment(commentContent, rehabId);
    setRefreshComments(true);
  };

  function checkFormValues() {
    let isFormValid = true;
    if (commentContent === '') {
      isFormValid = false;
    }

    return isFormValid;
  }

  useEffect(() => {
    if (refreshComments) {
      if (checkUserCookie()) {
        getComments(rehabId).then((result) => {
          setCommentsList(result);
          setLoading(false);
          setRefreshComments(false);
        });
      }
    }
  }, [refreshComments]);

  useEffect(() => {
    setIsFormValid(checkFormValues);
  }, [commentContent]);

  return (
    <div className={classes.box}>
      <div className="answer">
        <Typography variant={'h6'}>Dodaj nowy komentarz</Typography>
        <TextField
          multiline
          rows={4}
          placeholder="Treść komentarza..."
          fullWidth
          value={commentContent}
          onChange={handleCommentContent}
        ></TextField>
        <Button disabled={!isFormValid} onClick={handleCreateComment}>
          Dodaj
        </Button>
      </div>
      {loading && (
        <div className={classes.loaderWrapper}>
          <BallTriangle
            heigth="70"
            width="70"
            color="#27BDBE"
            ariaLabel="loading-indicator"
          />
        </div>
      )}
      {!loading && commentsList.length
        ? [...commentsList]
            .reverse()
            .map(
              (comment) =>
                (comment = (
                  <SingleComment
                    key={comment.comment_id}
                    name={comment.author_name}
                    date={comment.created_at.slice(0, 10)}
                    comment={comment.content}
                  />
                ))
            )
        : 'Brak komentarzy'}
      {/* {commentsList.length ? <Button>Załaduj więcej</Button> : ''} */}
    </div>
  );
}

export default Comments;

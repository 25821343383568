import React, {useState} from 'react';
import useStyles from "./SectionHeader.style";
import { useTranslation } from 'react-i18next';
import {
    IconButton,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function SectionHeader({ header='', buttonUrl=null }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root + ' ' + 'component' + ' ' + 'text-component'+ ' ' + 'sectionheader-component'}>
            <div className={'component-inner'}>
                <Typography component={'h2'} variant={'h4'}>
                    {header}
                    {
                        buttonUrl != null ?
                            <Link to={buttonUrl}>
                                <IconButton>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Link>
                        : null
                    }
                </Typography>

            </div>
        </div>
    );
}

export default SectionHeader;

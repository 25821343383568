import React, { useState } from 'react';
import useStyles from './SingleComment.style';
import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import editBtn from '../../../../assets/svg/edit-btn.svg';
import removeBtn from '../../../../assets/svg/remove-btn.svg';

function SingleComment({
  name = 'Domyślne imię',
  date = 'Domyślna data',
  role = 'Domyślna rola',
  comment = 'Domyślny komentarz',
  key,
}) {
  const classes = useStyles();

  function getFirstLetters(str) {
    const firstLetters = str
      .split(' ')
      .map((word) => word[0])
      .join('');

    return firstLetters;
  }

  return (
    <div className={classes.box}>
      <div>
        <div className="author">
          <div className="avatar">
            <div>
              <Typography variant={'body2'}>{getFirstLetters(name)}</Typography>
            </div>
          </div>
          <div className="name">
            <div>
              <Typography variant={'body2'}>{name}</Typography>
              <Typography variant={'caption'}>{date}</Typography>
            </div>
            <p className="role">{role}</p>
          </div>
        </div>
        <div className="buttons">
          {/*<button>*/}
          {/*  <img src={editBtn} alt="" />*/}
          {/*</button>*/}
          {/*<button>*/}
          {/*  <img src={removeBtn} alt="" />*/}
          {/*</button>*/}
        </div>
      </div>
      <div>
        <Typography variant={'caption'}>{comment}</Typography>
      </div>
    </div>
  );
}

export default SingleComment;

import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '1.5rem 3rem',
    boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.05)',
    margin: '0 1rem',
    '& div': {
      marginBottom: '2rem',
      position: 'relative',

      '&:before': {
        content: '""',
        height: '100%',
        width: '4px',
        backgroundColor: '#27BDBE',
        position: 'absolute',
        borderRadius: '0px 3px 3px 0px',
        left: '-3rem',
      },
      '& h6': {
        color: '#27BDBE',
      },
    },
  },
  title: {
    display: 'flex',
    marginBottom: '3rem',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      '&:first-of-type': {
        marginRight: '2rem',
      },
      '& img': {
        marginRight: '1rem',
      },
      '& p': {
        color: '#68747B',
      },
    },
    '@media(max-width:530px)': {
      flexDirection: 'column',
      '& >div': {
        '&:first-of-type': {
          marginBottom: '2rem',
        },
      },
    },
  },
  modalContent: {
    backgroundColor: 'white',
    width: '50%',
    margin: 'auto',
    padding: '4rem',
    position: 'relative',
    marginTop: '0',
    marginBottom: '0',
    marginRight: '0',
    minHeight: '100%',
    '& .single-task': {
      border: '2px solid #EFF2F3',
      borderRadius: '1.4rem',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '2rem',
      transition: 'all 250ms ease',

      '&.task-done': {
        backgroundColor: '#B8C2C8',
        borderColor: '#B8C2C8',
      },
      '& div': {
        width: '100%',
        marginBottom: '1.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& button': {
          padding: '0',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
        },
      },
      '& span': {
        marginBottom: '1.5rem',
      },
    },
    '@media(max-width:900px)': {
      width: '70%',
    },
    '@media(max-width:600px)': {
      width: '85%',
      padding: '2rem',
    },
  },
  closeModal: {
    position: 'absolute',
    top: '0',
    left: '-6rem',
    backgroundColor: 'transparent',
    width: '5.2rem',
    height: '5.2rem',
    padding: '0',
    border: 'none',
    zIndex: '1',
    '&:hover': {
      cursor: 'pointer',
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    '@media(max-width:600px)': {
      width: '4.2rem',
      height: '4.2rem',
      left: '-5rem',
    },
  },
});

export default useStyles;

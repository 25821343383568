import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '1.5rem',
    maxWidth: '42rem',
    display: 'flex',
    alignItems: 'center',
    '& .component-inner': {
      '&.points-inner': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    },
    '& h3.points-count': {
      color: '#27BDBE',
      backgroundColor: '#D4F2F2',
      borderRadius: '1.4rem',
      padding: '1rem 2rem',
      display: 'inline-flex',
      marginBottom: '0 !important',
      marginRight: '2rem',
    },
    '& h5.sub-title': {
      marginBottom: '0.5rem !important',
    },
  },
});

export default useStyles;

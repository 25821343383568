import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    unit: 'px',
  },
  direction: 'ltr',
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    htmlFontSize: 10,
    fontSize: 10,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightSemiBold: 600,
    fontWeightExtraBold: 800,
    h1: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '9.6rem',
      lineHeight: '11.2rem',
      fontWeight: 600,
    },
    h2: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '6.0rem',
      lineHeight: '7.2rem',
      fontWeight: 600,
    },
    h3: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '4.8rem',
      lineHeight: '5.6rem',
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '3.4rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '2.4rem',
      lineHeight: '3.0rem',
      fontWeight: 600,
    },
    h6: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '2rem',
      lineHeight: '2.4rem',
      fontWeight: 600,
    },
    button: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
    },
    body1: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      fontWeight: 600,
    },
    body2: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.4rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.6rem',
      lineHeight: 1.5,
      fontWeight: '600',
    },
    subtitle2: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.4rem',
      lineHeight: 1.5,
    },
    caption: {
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '1.2rem',
      lineHeight: '1.3rem',
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      50: '#e1f5fc',
      100: '#b3e6f7',
      200: '#82d5f3',
      300: '#53c4ee',
      400: '#2fb7ec',
      500: '#08abe9',
      600: '#009ddb',
      700: '#008ac8',
      800: '#0079b4',
      900: '#005993',
      main: '#009ddb',
      light: '#82d5f3',
      dark: '#005993',
    },
    background: {
      default: '#EFF2F3',
      paper: '#ffffff',
    },
    common: {
      black: '#42515A',
      white: '#ffffff',
    },
    text: {
      primary: '#42515A',
      secondary: '#B2BAC2',
      disabled: 'rgba(0, 0, 0, 0.5)',
      icon: 'rgba(0, 0, 0, 0.5)',
    },
    grey: {
      50: '#eef1f3',
      100: '#d4dce1',
      200: '#b8c5cd',
      300: '#9aadb8',
      400: '#849aa8',
      500: '#6d8898',
      600: '#617986',
      700: '#516470',
      800: '#42515A',
      900: '#303b42',
    },
  },
  shape: {
    borderRadius: '1.4rem',
  },
  shadows: {
    0: 'none',
    1: '0px 12px 10px 0px rgba(0,0,0,0.05)',
    2: '0px 12px 25px rgba(0, 0, 0, 0.1)',
    3: '0px 12px 25px rgba(0, 0, 0, 0.15)',
    low: '0px 12px 10px 0px rgba(0,0,0,0.05)',
    medium: '0px 12px 25px rgba(0, 0, 0, 0.1)',
    high: '0px 12px 25px rgba(0, 0, 0, 0.15)',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        main: {
          // paddingTop: document.getElementById("main-header").offsetHeight,
          // paddingTop: 'calc(' + document.getElementById("main-header").offsetHeight + 'px + 2rem)' ,
        },
        section: {
          padding: '3rem 0',
          // backgroundColor: 'red'
        },
        p: {
          '& a': {
            color: 'inherit',
            textDecoration: 'inherit',
          },
        },
        a: {
          color: 'inherit',
        },
        img: {
          display: 'block',
          position: 'relative',
          height: 'auto',
          width: 'auto',
        },
        '& .component': {
          marginBottom: '2rem' + ' !important',

          '&:last-of-type': {
            marginBottom: 0 + ' !important',
          },
        },
        h1: {
          '&:not(:last-child)': {
            marginBottom: '1rem',
          },
        },
        h2: {
          '&:not(:last-child)': {
            marginBottom: '1rem',
          },
        },
        h3: {
          '&:not(:last-child)': {
            marginBottom: '1rem',
          },
        },
        h4: {
          '&:not(:last-child)': {
            marginBottom: '1rem',
          },
        },
        h5: {
          '&:not(:last-child)': {
            marginBottom: '1rem',
          },
        },
        h6: {
          '&:not(:last-child)': {
            marginBottom: '1rem',
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginBottom: '2rem' + ' !important',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          // textTransform: 'none',
          //   padding: '0 !important',
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            color: '#ffffff',
            backgroundColor: '#82d5f3',
          },
        },
        sizeMedium: {
          color: '#ffffff',
          backgroundColor: '#009ddb',
          borderColor: '#009ddb',
          borderWidth: 2,
          padding: '1.2rem 2.2rem',
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          opacity: 1,
          boxShadow: 'none',

          '&:hover': {
            color: '#ffffff',
            backgroundColor: '#82d5f3',
            borderColor: '#82d5f3',
            borderWidth: 2,
            boxShadow: '0px 12px 10px 0px rgba(0,0,0,0.05)',
          },
          '&.gray-border-btn': {
            background: 'transparent',
            border: '2px solid #B8C2C8',
            color: '#42515A',
            '&:hover': {
              backgroundColor: 'rgba(66, 81, 90, 0.2)',
            },
          },
        },

        // "sizeLarge": {
        //     "padding": "1.5rem 1rem",
        //     "fontSize": "1rem",
        //     "lineHeight": 1.3125,
        //     "letterSpacing": 0,
        //     "fontWeight": 700
        // },
        // "sizeSmall": {
        //     "padding": "4px 8px",
        //     "marginLeft": "-8px"
        // },
        // "containedPrimary": {
        //     "backgroundColor": "#007FFF",
        //     "color": "#fff"
        // }
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          color: '#42515A',
          backgroundColor: '#ffffff',
          borderColor: '#D9DCDE',
          borderRadius: '1.4rem',
          borderWidth: 2,
          borderStyle: 'solid',
          padding: '0.8rem',
          opacity: 1,
          marginLeft: '1rem',

          '&:hover': {
            color: '#42515A',
            backgroundColor: '#ffffff',
            borderColor: '#B8C2C8',
            borderRadius: '1.4rem',
            borderWidth: 2,
            borderStyle: 'solid',
            boxShadow: '0px 12px 10px 0px rgba(0,0,0,0.05)',
          },
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.calendar-wrapper ': {
            '& .MuiSvgIcon-root': {
              color: '#27BDBE',
              fontSize: '2rem',
            },
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '1rem 1.4rem !important',
          borderRadius: '1.4rem !important',
          fontFamily: '"Montserrat", sans-serif',
          border: '2px solid #d9dcde !important',
          color: '#42515A',
          fontSize: '1.4rem',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          '& .MuiInputBase-input': {
            padding: '0 !important',
            // borderRadius: '1rem',
            // fontFamily: '"Montserrat", sans-serif',
            // border: '2px solid #d9dcde !important',
            // color: '#42515A',
            // fontSize: '1.4rem',
            // fontWeight: '600',
            // display: 'flex',
            // alignItems: 'center',
          },

          '& fieldset': {
            border: 'none',
          },
          '&:hover fieldset': {
            border: 'none',
          },
          '&.Mui-focused fieldset': {
            border: 'none',
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '0.8rem',
          borderRadius: '2.2rem',
          '& .MuiLinearProgress-bar': {
            borderRadius: '2.2rem',
          },
        },
      },
    },

    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'white',
    //       borderRadius: '1.4rem',
    //       boxShadow: 'none',
    //       border: '2px solid #D9DCDE',
    //     },
    //   },
    // },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: '600',
          '&.Mui-selected': {
            backgroundColor: 'rgba(39,189,190, 0.6)',
            '&:hover': {
              backgroundColor: 'rgba(39,189,190, 0.6)',
            },
          },
        },
      },
    },
  },

  // "components": {
  //     "MuiCssBaseline": {
  //         "defaultProps": {
  //             "enableColorScheme": true
  //         }
  //     },
  //     "MuiButtonBase": {
  //         "defaultProps": {
  //             "disableTouchRipple": true
  //         }
  //     },
  //     "MuiButton": {
  //         "defaultProps": {
  //             "disableElevation": true
  //         },
  //         "styleOverrides": {
  //             "label": {
  //                 fontFamily: '"Lexend Deca"',
  //             },
  //             "root":{
  //                 fontFamily: '"Lexend Deca"',
  //                 textTransform: "none",
  //             },
  //             "sizeLarge": {
  //                 "padding": "0.875rem 1rem",
  //                 "fontSize": "1rem",
  //                 "lineHeight": 1.3125,
  //                 "letterSpacing": 0,
  //                 fontFamily: '"Lexend Deca"',
  //                 "fontWeight": 700
  //             },
  //             "sizeSmall": {
  //                 "padding": "4px 8px",
  //                 "marginLeft": "-8px"
  //             },
  //             "containedPrimary": {
  //                 "backgroundColor": "#007FFF",
  //                 "color": "#fff"
  //             }
  //         },
  //     },
  //     "MuiTypography":{
  //         "styleOverrides": {
  //             "root": {
  //                 fontFamily: '"Lexend Deca"',
  //             }
  //         }
  //     },
  // },
  // "palette": {
  //     "primary": {
  //         "50": "#e1f5fc",
  //         "100": "#C2E0FF",
  //         "200": "#99CCF3",
  //         "300": "#66B2FF",
  //         "400": "#3399FF",
  //         "500": "#007FFF",
  //         "600": "#0072E5",
  //         "700": "#0059B2",
  //         "800": "#004C99",
  //         "900": "#003A75",
  //         "main": "#ff9800",
  //         "light": "#66B2FF",
  //         "dark": "#0059B2",
  //         "contrastText": "rgba(0, 0, 0, 0.87)"
  //     },
  //     "divider": "rgba(194, 224, 255, 0.08)",
  //     "primaryDark": {
  //         "50": "#E2EDF8",
  //         "100": "#CEE0F3",
  //         "200": "#91B9E3",
  //         "300": "#5090D3",
  //         "400": "#265D97",
  //         "500": "#1E4976",
  //         "600": "#173A5E",
  //         "700": "#132F4C",
  //         "800": "#001E3C",
  //         "900": "#0A1929",
  //         "main": "#5090D3"
  //     },
  //     "background": {
  //         "default": "#001E3C",
  //         "paper": "#0A1929"
  //     },
  //     "common": {
  //         "black": "#1D1D1D",
  //         "white": "#fff"
  //     },
  //     "text": {
  //         "primary": "#fff",
  //         "secondary": "#B2BAC2",
  //         "disabled": "rgba(255, 255, 255, 0.5)",
  //         "icon": "rgba(255, 255, 255, 0.5)"
  //     },
  //     "grey": {
  //         "50": "#F3F6F9",
  //         "100": "#E7EBF0",
  //         "200": "#E0E3E7",
  //         "300": "#CDD2D7",
  //         "400": "#B2BAC2",
  //         "500": "#A0AAB4",
  //         "600": "#6F7E8C",
  //         "700": "#3E5060",
  //         "800": "#2D3843",
  //         "900": "#1A2027",
  //         "A100": "#f5f5f5",
  //         "A200": "#eeeeee",
  //         "A400": "#bdbdbd",
  //         "A700": "#616161"
  //     },
  //     "error": {
  //         "50": "#FFF0F1",
  //         "100": "#FFDBDE",
  //         "200": "#FFBDC2",
  //         "300": "#FF99A2",
  //         "400": "#FF7A86",
  //         "500": "#FF505F",
  //         "600": "#EB0014",
  //         "700": "#C70011",
  //         "800": "#94000D",
  //         "900": "#570007",
  //         "main": "#EB0014",
  //         "light": "#FF99A2",
  //         "dark": "#C70011",
  //         "contrastText": "#fff"
  //     },
  //     "success": {
  //         "50": "#E9FBF0",
  //         "100": "#C6F6D9",
  //         "200": "#9AEFBC",
  //         "300": "#6AE79C",
  //         "400": "#3EE07F",
  //         "500": "#21CC66",
  //         "600": "#1DB45A",
  //         "700": "#1AA251",
  //         "800": "#178D46",
  //         "900": "#0F5C2E",
  //         "main": "#1DB45A",
  //         "light": "#6AE79C",
  //         "dark": "#1AA251",
  //         "contrastText": "rgba(0, 0, 0, 0.87)"
  //     },
  //     "warning": {
  //         "50": "#FFF9EB",
  //         "100": "#FFF3C1",
  //         "200": "#FFECA1",
  //         "300": "#FFDC48",
  //         "400": "#F4C000",
  //         "500": "#DEA500",
  //         "600": "#D18E00",
  //         "700": "#AB6800",
  //         "800": "#8C5800",
  //         "900": "#5A3600",
  //         "main": "#DEA500",
  //         "light": "#FFDC48",
  //         "dark": "#AB6800",
  //         "contrastText": "rgba(0, 0, 0, 0.87)"
  //     },
  //     "secondary": {
  //         "main": "#bf360c",
  //         "light": "rgb(203, 94, 60)",
  //         "dark": "rgb(133, 37, 8)",
  //         "contrastText": "#fff"
  //     },
  //     "info": {
  //         "main": "#29b6f6",
  //         "light": "#4fc3f7",
  //         "dark": "#0288d1",
  //         "contrastText": "rgba(0, 0, 0, 0.87)"
  //     },
  // },
  // "shape": {
  //     "borderRadius": 8
  // },
});

export default theme;

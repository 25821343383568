import React from 'react';
import theme from "../../../../theme";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        '& .label-row': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '1rem',

            '& > div': {
                paddingLeft: '2rem',
            }
        },
        '& .list-position-row': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.common.white,
            marginBottom: '1rem',
            borderRadius: theme.shape.borderRadius,

            '& > div': {
                padding: '1rem 2rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
            }
        },
        '& .pos-id': {
            width: 'calc(100% / 16 * 1)',
        },
        '& .pos-name': {
            width: 'calc(100% / 16 * 6)',
        },
        '& .pos-date': {
            width: 'calc(100% / 16 * 2)',
        },
        '& .pos-user': {
            width: 'calc(100% / 16 * 3)',
        },
        '& .pos-actions': {
            width: 'calc(100% / 16 * 2)',
        },
    }
});

export default useStyles;
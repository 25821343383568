import Cookies from 'universal-cookie';

function getUser(
  name = 'admin@fastsite.pl',
  password = 'Fast2022!',
  handleLoading
) {
  var axios = require('axios');

  handleLoading(true);
  var data = JSON.stringify({
    username: name,
    password: password,
  });

  var config = {
    method: 'post',
    url: process.env.REACT_APP_API_URL + '/authorization/token',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  const cookies = new Cookies();

  axios(config)
    .then(function (response) {
      console.log('DATA status code:' + JSON.stringify(response.data.code));

      cookies.set('userData', response.data, { path: '/' });
      cookies.set('responseStatus', response.data.code, { path: '/' });
      handleLoading(false);
      window.location.href = '/';
    })
    .catch(function (error) {
      console.log(error);
      cookies.set('responseStatus', 'error', { path: '/' });
      handleLoading(false);
    });
}

export default getUser;

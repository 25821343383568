import React, { useState, useEffect } from 'react';
// import useStyles from './TaskGroup.style';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';

import closeModal from '../../../../assets/svg/close-modal.svg';
import Modal from '@mui/material/Modal';
import removeTask from '../../../../assets/svg/remove-task.svg';
import dateIcon from '../../../../assets/svg/date.svg';
import taskNumber from '../../../../assets/svg/task-number.svg';

function SingleTask({
  exercise,
  exercises,
  setExercises,
  setExercisesChanged,
}) {
  const [buttonText, setButtonText] = useState(
    exercise.status === '1' ? 'Oznacz jako zrobione' : 'Wykonane'
  );

  const [status, setStatus] = useState(exercise.status);

  function handleCompleteExercise(event) {
    setExercisesChanged(true);
    setButtonText('Wykonane');
    if (buttonText === 'Wykonane') setButtonText('Oznacz jako zrobione');
    if (status === '0') {
      //   updateTaskStatus(rehabId, scheduleId, exercise.id, '1');
      setStatus('1');
    } else {
      setStatus('0');
      //   updateTaskStatus(rehabId, scheduleId, exercise.id, '0');
    }
    //
  }

  function handleRemoveTask() {
    console.log('kliknieto');
    setExercisesChanged(true);
    let excersisesTemp = [...exercises];

    excersisesTemp = excersisesTemp.filter((ex) => ex.id !== exercise.id);
    console.log(excersisesTemp);
    setExercises(excersisesTemp);
  }

  useEffect(() => {
    let excersisesTemp = [...exercises];

    exercises.forEach((excerc) => {
      if (excerc.id === exercise.id) {
        excerc.status = status;
      }
    });

    setExercises(excersisesTemp);
    // console.log(excersisesTemp);
  }, [status]);

  return (
    <div className={status === '1' ? 'single-task' : 'single-task task-done'}>
      <div>
        <Typography>{exercise.name}</Typography>
        {/*<button onClick={handleRemoveTask}>*/}
        {/*  /!* <img alt="" src={removeTask} /> *!/X*/}
        {/*</button>*/}
      </div>
      <Typography variant={'caption'}>{exercise.description}</Typography>

      <Typography variant={'caption'}>{exercise.duration}</Typography>

      {/* <Typography variant={'caption'}>Status: {status}</Typography> */}

      {/*<Button value={exercise.id} onClick={handleCompleteExercise}>*/}
      {/*  {buttonText}*/}
      {/*</Button>*/}
    </div>
  );
}

export default SingleTask;

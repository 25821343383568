import React, { useState } from 'react';
import useStyles from './ExcerciseCard.style';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';

function ExcerciseCard({ title, imageUrl, slug }) {
  const classes = useStyles();

  return (
    <div className={classes.excerciseCard}>
      <Link to={slug}>
        <div className="text-wrapper">
          <Typography variant={'body2'}>{title}</Typography>
        </div>
        <div className="wrapper">
          <div className="image-wrapper">
            <img alt="" src={imageUrl} />
            {/*<div className="mask"></div>*/}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ExcerciseCard;

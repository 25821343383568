import React, { useState, useEffect } from 'react';
import useStyles from './Rehabilitation.style';
import { Button, Container, Grid, Typography } from '@mui/material';
import { generateHelmet } from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import Footer from '../../../components/General/Footer/Footer';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import SingleTherapy from '../../../components/Modules/Rehabilitation/SingleTherapy/SingleTherapy';
import SingleComment from '../../../components/Modules/Rehabilitation/SingleComment/SingleComment';
import { Link } from 'react-router-dom';
import AdminSingleTherapy from '../../../components/Modules/Rehabilitation/AdminSingleTherapy/AdminSingleTherapy';
import LastCommentsBox from '../../../components/Modules/Rehabilitation/LastCommentsBox/LastCommentsBox';
import getRehabilitation from '../../../api/getRehabilitation';
import { BallTriangle } from 'react-loader-spinner';
import checkUserCookie from '../../../api/checkUserCookie';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import createRehabilitation from '../../../api/createRehabilitation';
import Snackbar from '@mui/material/Snackbar';
import getUserName from '../../../api/getUserName';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useParams } from 'react-router-dom';
import removeBtn from '../../../assets/svg/remove-btn.svg';
import createSchedule from '../../../api/createSchedule';
import { v4 as uuidv4 } from 'uuid';

function NewSchedule() {
  const classes = useStyles();
  const cookies = new Cookies();
  const { t } = useTranslation();

  let params = useParams();

  const [rehabilitation, setRehabilitation] = useState(false);
  const [loading, setLoading] = useState(true);

  const [scheduleDate, setScheduleDate] = useState('2022-07-13');
  const [exercises, setExercises] = useState([]);

  const [id, setId] = useState(0);
  const [name, setName] = useState('no name');
  const [durration, setDurration] = useState('15 min');
  const [description, setDescription] = useState('Opis ćwiczenia');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const action = <React.Fragment></React.Fragment>;

  useEffect(() => {
    getRehabilitation(`/rehabilitation/${params.rehabilitationSlug}`).then(
      (result) => {
        setRehabilitation(result);
        setLoading(false);
        console.log(result);
      }
    );
  }, []);

  function handleScheduleDateChange(event) {
    setScheduleDate(event.target.value);
  }

  function handleNameChange(event) {
    setName(event.target.value);
  }
  function handleDurrationChange(event) {
    setDurration(event.target.value);
  }
  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }
  function handleAddExercise(event) {
    setExercises([
      ...exercises,
      {
        id: id,
        positon: id, // intialy id and postion are equal
        name: name,
        description: description,
        duration: durration,
        status: '1',
      },
    ]);
    setId(id + 1);
    console.log(exercises);
    setOpenSnackbar(true);
    setSnackbarMessage('Dodano ćwiczenie');
  }

  function handleAddScheduleClick() {
    createSchedule(params.rehabilitationSlug, scheduleDate, exercises);
    setOpenSnackbar(true);
    setSnackbarMessage('Dodano harmonogram');
  }

  function handleMoveUp(event) {
    let exercisesTemp = [...exercises];

    let actualExercisePosition = parseInt(event.target.value);
    console.log(event.target.value);

    if (actualExercisePosition > 0) {
      for (let i = 0; i < exercisesTemp.length; i++) {
        if (exercisesTemp[i].positon == actualExercisePosition) {
          console.log('Match');
          let temp = exercisesTemp[i - 1].positon;
          exercisesTemp[i - 1].positon = exercisesTemp[i].positon;
          exercisesTemp[i].positon = temp;
          exercisesTemp.sort((a, b) => a.positon - b.positon);
          setExercises(exercisesTemp);
          return;
        }
      }
    }
  }

  function handleMoveDown(event) {
    let exercisesTemp = [...exercises];

    let actualExercisePosition = parseInt(event.target.value);

    if (actualExercisePosition < id - 1) {
      for (let i = 0; i < exercisesTemp.length; i++) {
        if (exercisesTemp[i].positon == actualExercisePosition) {
          console.log('Match');
          let temp = exercisesTemp[i + 1].positon;
          exercisesTemp[i + 1].positon = exercisesTemp[i].positon;
          exercisesTemp[i].positon = temp;
          exercisesTemp.sort((a, b) => a.positon - b.positon);
          setExercises(exercisesTemp);
          console.log(exercisesTemp);
          return;
        }
      }
    }
  }

  function handleRemoveExercise(event) {
    console.log(event.target.value);
    let exercisesTemp = exercises.filter(function (element) {
      return element.id != event.target.value;
    });
    console.log(exercisesTemp);
    setExercises(exercisesTemp);
  }

  return (
    <>
      {generateHelmet('Rehabilitacje', 'Moduł rehabilitacyjny aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container>
            {loading && (
              <div className={classes.loaderWrapper}>
                <BallTriangle
                  heigth="70"
                  width="70"
                  color="#27BDBE"
                  ariaLabel="loading-indicator"
                />
              </div>
            )}

            {!loading && (
              <>
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <BreadcrumbsComponent
                      parent="Rehabilitacje"
                      parentLink="/rehabilitacje"
                      parent1={rehabilitation.name}
                      parentLink1={`/rehabilitacje/${rehabilitation.rehab_id}`}
                      child="Dodaj nowy harmonogram"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <div className={classes.scheduleTitleBox}>
                      <Typography variant={'h4'}>
                        Dodaj nowy harmonogram
                      </Typography>
                      <Button onClick={handleAddScheduleClick}>
                        Zapisz harmonogram
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <Typography variant={'body1'}>
                      Podaj termin wykonania, dodaj ćwiczenia, a następnie
                      zapisz harmonogram.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={1} xs={12} />
                  <Grid item lg={10} xs={12}>
                    <div className={classes.newScheduleBox}>
                      <div className={classes.input + ' ' + ''}>
                        <label>Termin wykonania</label>
                        <TextField
                          placeholder="Termin wykonania (dd-mm-rrrr)"
                          fullWidth
                          variant="outlined"
                          onChange={handleScheduleDateChange}
                        />
                      </div>
                    </div>
                    <div className={classes.newScheduleBox}>
                      <div className={classes.input + ' ' + ''}>
                        <label>Nazwa ćwiczenia</label>
                        <TextField
                          placeholder="Nazwa ćwiczenia"
                          fullWidth
                          variant="outlined"
                          onChange={handleNameChange}
                        />
                      </div>
                      <div className={classes.input + ' ' + ''}>
                        <label>Czas trwania ćwiczenia</label>
                        <TextField
                          placeholder="Czas trwania ćwiczenia"
                          fullWidth
                          variant="outlined"
                          onChange={handleDurrationChange}
                        />
                      </div>
                      <div className={classes.input + ' ' + ''}>
                        <label>Opis ćwiczenia</label>
                        <TextField
                          placeholder="Opis ćwiczenia"
                          fullWidth
                          multiline
                          variant="outlined"
                          rows={4}
                          onChange={handleDescriptionChange}
                        />
                      </div>
                      <Button onClick={handleAddExercise}>
                        Dodaj ćwiczenie
                      </Button>
                    </div>
                  </Grid>
                  <Grid item lg={1} xs={12} />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={1} xs={12} />
                  <Grid item lg={10} xs={12}>
                    <div className={classes.sceduleTable}>
                      <div className="table-head">
                        <div className="sort" />

                        <div className="title">
                          <Typography variant={'body2'}> Ćwiczenie</Typography>
                        </div>
                        <div className="description">
                          <Typography variant={'body2'}> Opis</Typography>
                        </div>
                        <div className="duration">
                          <Typography variant={'body2'}> Czas</Typography>
                        </div>
                        <div className="delete"></div>
                      </div>
                      <div className="table-body">
                        {!loading &&
                          (exercises.length
                            ? exercises.map(
                                (exercise) =>
                                  (exercise = (
                                    <div
                                      className="table-row-exercise"
                                      key={exercise.id}
                                      style={{ order: `${exercise.positon}` }}
                                    >
                                      <div className="sort">
                                        <div>
                                          <button
                                            onClick={handleMoveUp}
                                            value={exercise.positon}
                                          ></button>
                                          <span />
                                          <button
                                            onClick={handleMoveDown}
                                            value={exercise.positon}
                                          ></button>
                                        </div>
                                      </div>
                                      <div className="title">
                                        <Typography variant={'subtitle2'}>
                                          {exercise.name}
                                        </Typography>
                                      </div>
                                      <div className="description">
                                        <Typography variant={'caption'}>
                                          {exercise.description}
                                        </Typography>
                                      </div>
                                      <div className="duration">
                                        <Typography variant={'h6'}>
                                          {exercise.duration}
                                        </Typography>
                                      </div>
                                      <div className="delete">
                                        <button
                                          onClick={handleRemoveExercise}
                                          value={exercise.id}
                                        />
                                      </div>
                                    </div>
                                  ))
                              )
                            : 'Brak ćwiczeń w harmonogramie')}

                        {/* {JSON.stringify(exercises)} */}
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={1} xs={12} />
                </Grid>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={4000}
                  onClose={handleSnackbarClose}
                  message={snackbarMessage}
                  action={action}
                />
              </>
            )}
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default NewSchedule;

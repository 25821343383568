import React from 'react';
import theme from '../../../theme';
import { createUseStyles } from 'react-jss';
import arrow from '../../../assets/svg/arrow.svg';
import arrowTurquoise from '../../../assets/svg/arrow-turquoise.svg';
import removeBtn from '../../../assets/svg/remove-btn.svg';

const useStyles = createUseStyles({
  slider: {
    // border: '1px solid red',
    '& button.slick-arrow': {
      backgroundColor: '#27BDBE;',
      width: '5rem',
      height: '5rem',
      borderRadius: '100%',
      zIndex: '1',
      boxShadow: '0px 12px 25px rgba(0, 0, 0, 0.1)',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
      border: '2px solid #27BDBE',
      transition: 'all 250ms ease',
      //   top: '20%',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        content: '""',
        width: '2rem',
        height: '1.6rem',
        display: 'block',
        backgroundImage: `url(${arrow})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'all 250ms ease',
      },
      '&.slick-next': {
        right: '0',
        '&:after': {
          transform: 'rotate(180deg)',
        },
      },
      '&.slick-prev': {
        display: 'none !important',
      },
      '&:hover': {
        backgroundColor: 'white',
        '&:after': {
          backgroundImage: `url(${arrowTurquoise})`,
        },
      },
    },
  },
  tableTherapy: {
    '& >div': {
      marginBottom: '1rem',
    },
    '& .labels': {
      display: 'flex',
      justifyContent: 'center',
      '@media(max-width:900px)': {
        display: 'none',
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
          fontSize: '1.4rem',
        },
      },
      '& .status': {
        width: 'calc(1 / 12 * 100%)',
      },
      '& .title': {
        width: 'calc(2 / 12 * 100%)',
      },
      '& .rehabilitator': {
        width: 'calc(2 / 12 * 100%)',
      },
      '& .progress': {
        width: 'calc(2 / 12 * 100%)',
        padding: '0 5px',
      },
      '& .date': {
        width: 'calc(2 / 12 * 100%)',
      },
      '& .button': {
        // width: 'calc(1 / 12 * 100%)',
        width: 'calc(3 / 12 * 100%)',
      },
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media(max-width:600px)': {
      flexDirection: 'column !important',
      alignItems: 'flex-start',
    },
  },
  newRehabilitationBox: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '2rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    width: '50%',
    marginBottom: '1.5rem',
    '& label': {
      fontWeight: '600',
      color: '#42515A',
      marginBottom: '0.5rem',
      display: 'block',
    },

    '&:nth-of-type(2n) ': {
      paddingRight: '1rem',
    },
    '&:nth-of-type(2n+1) ': {
      paddingLeft: '1rem',
    },
    '&:first-of-type': {
      width: '100%',
      paddingLeft: '0',
    },
  },
  tableBodyRows: {
    paddingTop: '0 !important',
  },
  customSelect: {
    '& .MuiPaper-root': {
      border: '1px solid red',
    },
  },
  newScheduleBox: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '2rem',
    '& >div': {
      width: '100%',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    '&:first-of-type': {
      marginBottom: '2rem',
    },
  },
  deleteRehabBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      backgroundColor: '#DA1F3C !important',
      '&:hover': {
        backgroundColor: '#E9798A !important',
      },
    },
  },
  scheduleTitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width:600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  sceduleTable: {
    marginTop: '4rem',
    '& .table-head': {
      display: 'flex',
      marginBottom: '1rem',
      '& >div': {
        padding: '0 0.5rem',
        display: 'flex',
        alignItems: 'center',
      },
      '& .sort': {
        width: 'calc(1 / 10 * 100%)',
      },
      '& .title': {
        width: 'calc(3 / 10 * 100%)',
      },
      '& .description': {
        width: 'calc(4 / 10 * 100%)',
      },
      '& .duration': {
        width: 'calc(1 / 10 * 100%)',
      },
      '& .delete': {
        width: 'calc(1 / 10 * 100%)',
      },
    },
    '& .table-body': {
      display: 'flex',
      flexDirection: 'column',
      // border: '1px solid red',
    },
    '& .table-row-exercise': {
      borderRadius: '1.4rem',
      backgroundColor: 'white',
      display: 'flex',
      padding: '2rem',
      marginBottom: '1rem',

      '& >div': {
        padding: '0 0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .sort': {
        width: 'calc(1 / 10 * 100%)',
        '& div': {
          display: 'flex',
          flexDirection: 'column',
          width: '5rem',
          '& button': {
            height: '4.3rem',
            border: 'none',
            backgroundColor: '#99D8F1',
            cursor: 'pointer',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&::after': {
              content: '""',
              width: '2rem',
              height: '1.6rem',
              display: 'block',
              backgroundImage: `url(${arrow})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              transition: 'all 250ms ease',
            },
            '&:first-of-type': {
              borderRadius: '1.4rem 0 0 0 ',
              '&::after': {
                transform: 'rotate(90deg)',
              },
            },
            '&:last-of-type': {
              borderRadius: '0 0 0 1.4rem',
              backgroundColor: '#009ddb',
              '&::after': {
                transform: 'rotate(-90deg)',
              },
            },
          },
          '& span': {
            height: '2rem',
            backgroundColor: '#66C4E9',
          },
        },
      },
      '& .title': {
        width: 'calc(3 / 10 * 100%)',
        justifyContent: 'flex-start',

        '& h6': {
          fontWeight: '600',
        },
      },
      '& .description': {
        width: 'calc(4 / 10 * 100%)',
        justifyContent: 'flex-start',

        '& span': {
          color: '#8E979C',
        },
      },
      '& .duration': {
        width: 'calc(1 / 10 * 100%)',
      },
      '& .delete': {
        width: 'calc(1 / 10 * 100%)',
        '& button': {
          border: ' 2px solid rgba(66, 81, 90, 0.2)',
          borderRadius: '1.2rem',
          backgroundColor: 'transparent',
          minWidth: '3.1rem',
          minHeight: '3.1rem',
          cursor: 'pointer',
          padding: '0',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:first-of-type': {
            marginRight: '1rem',
          },
          '&::after': {
            content: '""',
            width: '2rem',
            height: '1.6rem',
            display: 'block',
            backgroundImage: `url(${removeBtn})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            transition: 'all 250ms ease',
          },
        },
      },
    },
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default useStyles;

import React from 'react';
import theme from '../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  contactSection: {
    '& .info-box': {
      borderRadius: '1.4rem',
      padding: '2rem',
      backgroundColor: 'white',

      marginBottom: '2rem',
      '& h6': {
        color: '#68747b',
        fontFamily: "'Varela Round', sans-serif",
        margin: '1.5rem 0',
        fontWeight: '400',
      },
      '& a, & p': {
        fontSize: 'calc(18px + 6 * (100vw - 320px) / 1600)',
        color: '#42515a',
        display: 'block',
        paddingBottom: '0.5rem',
        margin: '0',
        transition: 'all 250ms ease',
      },
      '& a:hover': {
        color: '#27bdbe',
      },
    },
  },
});

export default useStyles;

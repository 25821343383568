import React, { useState } from 'react';
import useStyles from './ExerciseSlajder.style';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';
import img from '../../../../assets/img/placeholders/exercise.jpg';
import Slider from 'react-slick';

function ExcerciseSlajder({ title, description, galeria, videoUrl }) {
  const classes = useStyles();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={classes.slider}>
      <Slider {...settings}>
        {
          typeof galeria == 'object'
            ? galeria.length > 0 &&
              galeria.map(
                (imgUrl) =>
                  (imgUrl = (
                    <div className={classes.slide}>
                      <div className="image-wrapper">
                        <img alt="" src={imgUrl.url} />
                      </div>
                    </div>
                  ))
              )
            : ''
          // <p>Brak galerii w ACF</p>
        }
      </Slider>

      <div className="text-wrapper">
        <Typography variant={'h6'}>{title}</Typography>
        <Typography variant={'body2'}>{description}</Typography>
      </div>
      <div className="video-wrapper">
        <iframe
          title="Embedded video"
          src={videoUrl}
          width="690"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default ExcerciseSlajder;

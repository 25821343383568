import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '2.5rem',
    boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.05)',
    '& >div:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
      justifyContent: 'space-between',
    },
    '& .author': {
      display: 'flex',
    },
    '& .name': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& div': {
        display: 'inline-flex',
        alignItems: 'center',
        '& span': {
          color: '#68747B',
          marginLeft: '1rem',
        },
      },
      '& p.role': {
        color: '#009DDB',
        display: 'inline-flex',
        fontSize: '1rem',
        margin: '0',
        marginTop: '0.5rem',
        padding: '0 1rem',
        fontWeight: '600',
        textTransform: 'uppercase',
        background: '#CCEBF8',
        borderRadius: '3.4rem',
      },
    },
    '& .avatar': {
      marginRight: '1rem',

      width: '4.8rem',
      height: '4.8rem',
      border: '2px solid #F8AA9E',
      borderRadius: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#F8AA9E',
      padding: '4px',
      '& div': {
        width: '100%',
        height: '100%',
        borderRadius: '100%',
        backgroundColor: '#FEEEEC',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '& .buttons': {
      display: 'flex',
      alignItems: 'center',
      '& button': {
        border: ' 2px solid rgba(66, 81, 90, 0.2)',
        borderRadius: '1.2rem',
        backgroundColor: 'transparent',
        minWidth: '3.1rem',
        minHeight: '3.1rem',
        cursor: 'pointer',
        padding: '0',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:first-of-type': {
          marginRight: '1rem',
        },
        '& img': {
          objectFit: 'contain',
          width: '55%',
          height: '55%',
        },
      },
    },
  },
});

export default useStyles;

import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '0.5rem',
    border: '2px solid #D9DCDE',
    display: 'flex',
    marginTop: '1rem',
    '& div': {
      //   border: '1px solid red',
      '&.points': {
        width: 'calc(1 / 7 * 100%)',
        '& h5': {
          backgroundColor: '#D4F2F2',
          borderRadius: '1rem',
          color: '#27BDBE',
          padding: '1rem',
          display: 'inline-flex',
          justifyContent: 'center',
        },
      },
      '&.date': {
        width: 'calc(1 / 7 * 100%)',
        '& h6': {
          color: '#27BDBE',
        },
      },
      '&.comment': {
        width: 'calc(3 / 7 * 100%)',
        padding: '0 1rem',
        '& h6': {
          color: '#27BDBE',
        },
      },
      '&.coach': {
        width: 'calc(2 / 7 * 100%)',
        '& h6': {
          marginBottom: '0 !important',
        },
        '& p': {
          margin: '0 !important',
          backgroundColor: '#CCEBF8',
          borderRadius: '3.4rem',
          textTransform: 'uppercase',
          color: '#009DDB',
          fontSize: '1rem',
          fontWeight: '600',
          display: 'inline-flex',
          padding: '0 1rem',
        },
      },
    },
  },
});

export default useStyles;

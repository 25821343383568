import { createUseStyles } from 'react-jss';
import home from '../../assets/img/login-bg.png';

const useStyles = createUseStyles({
  loginSection: {
    '& .left-col': {
      height: '100%',
      backgroundImage: `url(${home})`,
      padding: '4rem',
      borderRadius: '1.4rem',
      '& h3': {
        color: '#F8F8F8',
      },
    },
    '& h4': {
      marginLeft: '2rem',
    },
    '& .login-box': {
      borderRadius: '1.4rem',
      backgroundColor: 'white',
      padding: '2.5rem',
      marginBottom: '2rem',
      maxWidth: '41rem',
      marginLeft: '2rem',
      '&:first-of-type': {
        '& button': {
          backgroundColor: '#27BDBE',
          '&:hover': {
            backgroundColor: '#7DD7D8',
          },
        },
      },
      '&:last-of-type': {
        marginBottom: '0',
        '& img': {
          marginBottom: '1rem',
        },
        '& button': {
          backgroundColor: '#F8AA9E',
          '&:hover': {
            backgroundColor: '#FBCCC5',
          },
        },
      },
    },
    '& h5, & h4, & h3': {
      fontFamily: "'Varela Round', sans-serif",
      fontWeight: '400',
    },
    '& h6': {
      color: '#8E979C',
    },
    '& .success': {
      '& div': {
        backgroundColor: '#27BDBE',
      },
    },
    '& .error': {
      '& div': {
        backgroundColor: '#DA1F3C',
      },
    },
  },
  input: {
    marginBottom: '1.5rem',
    '& label': {
      fontWeight: '600',
      color: '#42515A',
      marginBottom: '0.5rem',
      display: 'block',
    },
  },
});

export default useStyles;

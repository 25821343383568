import React from 'react';
import useStyles from "./Dashboard.style";
import {
    Container,
    Grid,
    Typography
} from "@mui/material";
import {generateHelmet} from "../../utils/helperFunctions";
import Header from "../../components/General/Header/Header";

function Faq() {
    const classes = useStyles();
    return (
        <>
            { generateHelmet('FAQ', 'Najczęściej zadawane pytania aplikacji hullo') }
            <Header/>
            <main>
                <section>
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Typography component="h1">
                                    FAQ
                                </Typography>
                                <Typography component={'h6'} variant={'h6'}>Odpowiedzi na najpopularniejsze pytania znajdziesz na <a href={process.env.REACT_APP_WWW_URL + '/faq/'} style={{textDecoration: 'underline'}}>hullo.pl</a></Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </section>
            </main>
        </>
    );
}

export default Faq;
import React from 'react';
import theme from '../../../../theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  excerciseCard: {
    backgroundColor: 'white',
    padding: '2rem',
    paddingBottom: '0',
    borderRadius: '1.4rem',
    height: '100%',

    '& a': {
      textDecoration: 'none',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '& .text-wrapper': {
      '& p': {
        position: 'relative',

        '&:before': {
          content: '""',
          height: '100%',
          width: '4px',
          backgroundColor: '#27BDBE',
          position: 'absolute',
          borderRadius: '0px 3px 3px 0px',
          left: '-2rem',
        },
      },
    },
    '& .image-wrapper': {
      borderRadius: '1.4rem 1.4rem 0 0',
      position: 'relative',
      // paddingTop: '80%',
      overflow: 'hidden',
      marginTop: '2rem',
      '& img': {
        position: 'relative',
        width: '100%',
        height: 'auto',
        // top: '0',
        // left: '0',
        // backgroundSize: 'cover',
      },
      '& .mask': {
        position: 'absolute',
        top: '20%',
        left: '-10%',
        width: ' 120%',
        height: ' 110%',
        transform: 'matrix(0.98, -0.15, 0.2, 0.99, 0, 0)',
        background:
          'linear-gradient(360deg, #F3A2A2 7.36%, rgba(243, 162, 162, 0) 88.57%)',
      },
    },
  },
});

export default useStyles;

import React, { useEffect, useState } from 'react';
import useStyles from './Footer.style';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../../assets/svg/hullo-logo.svg';
import euBanner from '../../../assets/img/sponsorzy-new.jpg';
import faq from '../../../assets/svg/footer-faq.svg';
import phone from '../../../assets/svg/footer-phone.svg';

function Footer(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer id={'main-header'} className={classes.root}>
      <Container className={classes.footerContainer} fixed>
        <Grid
          id={'upper-footer'}
          className={classes.upperFooter}
          container
          spacing={3}
        >
          <Grid item className={classes.footerItem} md={3} xs={12}>
            <img src={logo} alt={'hullo'} />
          </Grid>
          <Grid
            style={{ display: props.hideHours ? 'none' : 'block' }}
            item
            className={classes.footerItem + ' ' + classes.openingHoursItem}
            lg={3}
            md={12}
            xs={12}
          >
            <div className={'component' + ' ' + 'additionalinfo-component'}>
              <div className={'component-inner'}>
                <Typography component={'h6'} variant={'h6'}>
                  Godziny otwarcia
                </Typography>
                <Typography component={'p'} variant={'body1'}>
                  Budynek Rehabilitacyjno - Sportowy
                </Typography>
                <Typography component={'p'} variant={'body2'}>
                  <span>Pon. - Pt.</span>
                  <span>8:00 - 21:00</span>
                </Typography>
                <Typography component={'p'} variant={'body2'}>
                  <span>Sob.</span>
                  <span>8:00 - 16:00</span>
                </Typography>
                <Typography component={'p'} variant={'body2'}>
                  <span>Nd.</span>
                  <span>nieczynne</span>
                </Typography>
                <Typography component={'p'} variant={'body1'}>
                  Budynek Rekreacyjno - Edukacyjny
                </Typography>
                <Typography component={'p'} variant={'body2'}>
                  <span>Pon. - Pt.</span>
                  <span>10:00 - 18:00</span>
                </Typography>
                <Typography component={'p'} variant={'body2'}>
                  <span>Sob.</span>
                  <span>10:00 - 20:00</span>
                </Typography>
                <Typography component={'p'} variant={'body2'}>
                  <span>Nd.</span>
                  <span>10:00 - 18:00</span>
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            item
            className={classes.footerItem + ' ' + classes.faqItem}
            lg={3}
            md={6}
            xs={12}
          >
            <div className={'component' + ' ' + 'additionalinfo-component'}>
              <div className={'component-inner'}>
                <img src={faq} alt="" />
                <Typography variant={'body1'}>
                  Szukasz odpowiedzi na nurtujące Cię pytania?
                </Typography>
                <a href={process.env.REACT_APP_WWW_URL + '/faq/'}>
                  <Button>FAQ</Button>
                </a>
              </div>
            </div>
          </Grid>
          <Grid
            item
            className={classes.footerItem + ' ' + classes.contactItem}
            lg={3}
            md={6}
            xs={12}
          >
            <div className={'component' + ' ' + 'additionalinfo-component'}>
              <div className={'component-inner'}>
                <img src={phone} alt="" />

                <Typography variant={'body1'}>
                  Potrzebujesz naszej pomocy?
                </Typography>
                <a href={process.env.REACT_APP_WWW_URL + '/kontakt/'}>
                  <Button>Zadzwoń do nas</Button>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* <Grid
          id={'lower-footer'}
          className={classes.lowerFooter}
          container
          spacing={3}
        >
          <Grid
            item
            className={classes.footerItem + ' ' + classes.fundingItem}
            md={12}
            xs={12}
          >
            <img src={euBanner} alt={'dofinansowanie'} />
            <Typography component={'p'} variant={'caption'}>
              Projekt "Centrum Rehabilitacyjne Dla Dzieci i Młodzieży" jest
              współfinansowany ze środków Europejskiego Funduszu Rozwoju
              Regionalnego w ramach Programu Operacyjnego Polska Wschodnia 2014
              - 2020, oś priorytetowa I: Przedsiębiorcza Polska Wschodnia,
              działanie 1.3 Ponadregionalne powiązania kooperacyjne,
              poddziałanie 1.3.2 Tworzenie sieciowych produktów przez MSP.
            </Typography>
          </Grid>
        </Grid> */}
      </Container>
      <Container className={classes.upperFooterContainer}>
        <Grid
          id={'additional-footer'}
          className={classes.additionalFooter}
          container
          spacing={3}
        >
          <Grid item className={classes.footerItem} lg={4} md={4} xs={12}>
            <Typography component={'p'} variant={'caption'}>
              Copyright © 2022 Fundacja Rozwoju Warmii i Mazur. Wszystkie prawa
              zastrzeżone.
            </Typography>
          </Grid>
          <Grid item className={classes.footerItem} lg={6} md={6} xs={12}>
            <Button>
              <a target="_blank" href="https://hullo.pl/polityka-prywatnosci/">
                Polityka prywatności
              </a>
            </Button>
            <Button>
              <a
                target="_blank"
                href="https://hullo.pl/wp-content/uploads/2022/06/Regulaminu-sklepu-HULLOCENTRUM.pdf"
              >
                Regulamin
              </a>
            </Button>
          </Grid>
          <Grid item className={classes.footerItem} lg={2} md={2} xs={12}>
            <Typography
              component={'p'}
              variant={'caption'}
              style={{ textAlign: 'right' }}
            >
              Wdrożenie:{' '}
              <a href={'https://fastsite.pl'} target={'_blank'}>
                FASTSITE
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;

import React, { useState } from 'react';
import useStyles from './HelloBox.style';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import checkUserCookie from '../../../api/checkUserCookie';
import Cookies from 'universal-cookie';

function HelloBox(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const cookies = new Cookies();

  function getFirstLetters(str) {
    const firstLetters = str
      .split(' ')
      .map((word) => word[0])
      .join('');

    return firstLetters;
  }

  return (
    <Paper className={classes.box}>
      <div className={'component-inner'}>
        <Card>
          <CardContent>
            <div className={'image-wrapper'}>
              <div className="avatar">
                <div>
                  <Typography variant={'h6'}>
                    {' '}
                    {checkUserCookie() && checkUserCookie()
                      ? getFirstLetters(
                          cookies.get('userData')['description']['data'][
                            'displayName'
                          ]
                        )
                      : 'NN'}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={'content-wrapper'}>
              <Typography component={'h1'} variant={'h4'}>
                {checkUserCookie()
                  ? 'Hej ' +
                    cookies.get('userData')['description']['data'][
                      'displayName'
                    ]
                  : 'Hej'}
                ! 👋
              </Typography>
              <Typography component={'h2'} variant={'body1'}>
                {!checkUserCookie() ? t('helloBoxNotLogged') : ''}
                {checkUserCookie() &&
                cookies.get('userData')['description']['data']['role'] ===
                  'trainer'
                  ? t('helloBoxTrainer')
                  : ''}
                {checkUserCookie() &&
                cookies.get('userData')['description']['data']['role'] ===
                  'guardian'
                  ? t('helloBoxGuardian')
                  : ''}
                {checkUserCookie() &&
                cookies.get('userData')['description']['data']['role'] ===
                  'rehabilitee'
                  ? t('helloBoxRehabilitee')
                  : ''}

                {/*  : 'Masz do wykonania [liczba] ćwiczeń do ukończenia terapii oraz [liczba] odpowiedzi od Twoich trenerów! 🙌'*/}
                {/*: 'Komunikat dla osoby niezalogowanej'}*/}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    </Paper>
  );
}

export default HelloBox;

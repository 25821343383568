import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import theme from './theme';
import { CssBaseline } from '@mui/material';
import Home from './views/Home/Home';
import Login from './views/Dashboard/Login';
import NewsIndex from './views/Modules/News/NewsIndex';
import RehabilitationIndex from './views/Modules/Rehabilitation/RehabilitationIndex';
import ExercisesIndex from './views/Modules/Exercises/ExercisesIndex';
import BundlesIndex from './views/Modules/Bundles/BundlesIndex';
import GamesIndex from './views/Modules/Games/GamesIndex';
import Contact from './views/Static/Contact';
import Dashboard from './views/Dashboard/Dashboard';
import LoyaltyProgram from './views/Dashboard/LoyaltyProgram';
import Faq from './views/Dashboard/Faq';
import NotFound from './views/Static/NotFound';
import SingleExercise from './views/Modules/Exercises/SingleExercise';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SingleNews from './views/Modules/News/SingleNews';
import SingleRehabilitation from './views/Modules/Rehabilitation/SingleRehabilitation';
import NewRehabilitation from './views/Modules/Rehabilitation/NewRehabilitaton';
import NewSchedule from './views/Modules/Rehabilitation/NewSchedule';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />

          <Route exact path="/aktualnosci" element={<NewsIndex />} />
          <Route exact path="/aktualnosci/:postSlug" element={<SingleNews />} />

          <Route
            exact
            path="/rehabilitacje"
            element={<RehabilitationIndex />}
          />
          <Route
            exact
            path="/rehabilitacje/:rehabilitationSlug"
            element={<SingleRehabilitation />}
          />
          <Route
            exact
            path="/rehabilitacje/nowa-rehabilitacja"
            element={<NewRehabilitation />}
          />
          <Route
            exact
            path="/rehabilitacje/:rehabilitationSlug/nowy-harmonogram"
            element={<NewSchedule />}
          />

          <Route exact path="/cwiczenia" element={<ExercisesIndex />} />
          <Route
            exact
            path="/cwiczenia/:exerciseSlug"
            element={<SingleExercise />}
          />

          <Route exact path="/pakiety" element={<BundlesIndex />} />
          <Route exact path="/gry-ciekawostki" element={<GamesIndex />} />
          <Route exact path="/kontakt" element={<Contact />} />
          <Route exact path="/profil" element={<Dashboard />} />
          <Route
            exact
            path="/profil/program-lojalnościowy"
            element={<LoyaltyProgram />}
          />
          <Route exact path="/profil/FAQ" element={<Faq />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

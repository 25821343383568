import React, { useState, useEffect } from 'react';
import useStyles from './Exercises.style';
import { Container, Grid, Typography, Button } from '@mui/material';
import { generateHelmet } from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import ExcerciseCard from '../../../components/Modules/Excercises/ExcerciseCard/ExcerciseCard';
import Footer from '../../../components/General/Footer/Footer';
import CategoryFilter from '../../../components/Modules/Excercises/CategoryFilter/CategoryFilter';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import { BallTriangle } from 'react-loader-spinner';
import getCpt from '../../../api/getCpt';
import img from '../../../assets/img/placeholders/exercise.jpg';

function ExercisesIndex() {
  const classes = useStyles();

  const [posts, setPosts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState('0');

  useEffect(() => {
    getCpt(
      `cwiczenia?per_page=100&_fields[]=id&_fields[]=title&_fields[]=slug&_fields[]=acf.zdjecie_glowne.url&_fields[]=categories`
    ).then((result) => {
      setPosts(result);
      setLoading(false);
    });
  }, []);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    console.log(category);
  };

  return (
    <>
      {generateHelmet('Cwiczenia', 'Moduł ćwiczeniowy aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <BreadcrumbsComponent child="Ćwiczenia" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={4} xs={12}>
                <Typography variant={'h3'}>Ćwiczenia</Typography>
              </Grid>
              <Grid item lg={12} xs={12}>
                <div className={classes.categoryWrapper}>
                  <Typography variant={'h6'}>Aktualne kategorie</Typography>
                  <div>
                    <Button
                      onClick={handleCategoryChange}
                      value="0"
                      className={
                        category === '0'
                          ? 'active'
                          : '' + ' ' + 'gray-border-btn'
                      }
                    >
                      Wszystkie
                    </Button>
                    <Button
                      onClick={handleCategoryChange}
                      value="4"
                      className={
                        category === '4'
                          ? 'active'
                          : '' + ' ' + 'gray-border-btn'
                      }
                    >
                      Kręgosłup
                    </Button>
                    <Button
                      onClick={handleCategoryChange}
                      value="5"
                      className={
                        category === '5'
                          ? 'active'
                          : '' + ' ' + 'gray-border-btn'
                      }
                    >
                      Nogi
                    </Button>
                    <Button
                      onClick={handleCategoryChange}
                      value="6"
                      className={
                        category === '6'
                          ? 'active'
                          : '' + ' ' + 'gray-border-btn'
                      }
                    >
                      Stopy
                    </Button>
                    <Button
                      onClick={handleCategoryChange}
                      value="7"
                      className={
                        category === '7'
                          ? 'active'
                          : '' + ' ' + 'gray-border-btn'
                      }
                    >
                      Szyja
                    </Button>
                    <Button
                      onClick={handleCategoryChange}
                      value="8"
                      className={
                        category === '8'
                          ? 'active'
                          : '' + ' ' + 'gray-border-btn'
                      }
                    >
                      Mięśnie
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {loading && (
                <div className={classes.loaderWrapper}>
                  <BallTriangle
                    heigth="70"
                    width="70"
                    color="#27BDBE"
                    ariaLabel="loading-indicator"
                  />
                </div>
              )}

              {
                !loading &&
                  (parseInt(category) === parseInt(0)
                    ? posts.map(
                        (post) =>
                          (post = (
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <ExcerciseCard
                                title={`${post.title.rendered} `}
                                key={post.id}
                                slug={post.slug}
                                imageUrl={
                                  post.acf.hasOwnProperty('zdjecie_glowne')
                                    ? post.acf.zdjecie_glowne.url
                                    : img
                                }
                              />
                            </Grid>
                          ))
                      )
                    : posts
                        .filter(
                          (post) =>
                            post.categories.some(
                              (eachExerciseCategory) =>
                                parseInt(eachExerciseCategory) ===
                                parseInt(category)
                            )
                          // parseInt(post.categories[0]) === parseInt(category)
                        )
                        .map(
                          (post) =>
                            (post = (
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <ExcerciseCard
                                  title={`${post.title.rendered} `}
                                  key={post.id}
                                  slug={post.slug}
                                  imageUrl={
                                    post.acf.hasOwnProperty('zdjecie_glowne')
                                      ? post.acf.zdjecie_glowne.url
                                      : img
                                  }
                                />
                              </Grid>
                            ))
                        ))

                // posts
                //   .filter(
                //     (post) =>
                //       parseInt(post.categories[0]) === parseInt(category)
                //   )
                //   .map(
                //     (post) =>
                //       (post = (
                //         <Grid item lg={3} md={4} sm={6} xs={12}>
                //           <ExcerciseCard
                //             title={`${post.title.rendered} | ${post.categories[0]}`}
                //             key={post.id}
                //             slug={post.slug}
                //             imageUrl={
                //               post.acf.hasOwnProperty('zdjecie_glowne')
                //                 ? post.acf.zdjecie_glowne.url
                //                 : img
                //             }
                //           />
                //         </Grid>
                //       ))
                //   )
              }
            </Grid>
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ExercisesIndex;

import React, { useEffect, useState } from 'react';
import useStyles from './Header.style';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../../assets/svg/hullo-logo.svg';
import userIcon from '../../../assets/svg/user-icon.svg';
import checkUserCookie from '../../../api/checkUserCookie';
import Cookies from 'universal-cookie';
import Snackbar from '@mui/material/Snackbar';
import Hamburger from 'hamburger-react';
import euBanner from '../../../assets/img/sponsorzy-new.jpg';

function Header(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const cookies = new Cookies();

  const [isOpen, setOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [upperPaddingTop, setUpperPaddingTop] = useState('');

  const [fontSize, setFontSize] = useState(14);

  function userLogout() {
    const cookies = new Cookies();
    cookies.remove('userData', { path: '/' });
    cookies.remove('responseStatus', { path: '/' });

    setOpenSnackbar(true);
  }

  useEffect(() => {
    setUpperPaddingTop(
      document.getElementById('upper-header').offsetHeight + 10 + 'px'
    );
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const action = <React.Fragment></React.Fragment>;

  return (
    <>
      <header id={'main-header'} className={classes.root}>
        <div className={classes.fundingItem}>
          <img src={euBanner} alt={'dofinansowanie'} />
          <Typography component={'p'} variant={'caption'}>
            Projekt "Centrum Rehabilitacyjne Dla Dzieci i Młodzieży" jest
            współfinansowany ze środków Europejskiego Funduszu Rozwoju
            Regionalnego w ramach Programu Operacyjnego Polska Wschodnia 2014 -
            2020, oś priorytetowa I: Przedsiębiorcza Polska Wschodnia, działanie
            1.3 Ponadregionalne powiązania kooperacyjne, poddziałanie 1.3.2
            Tworzenie sieciowych produktów przez MSP.
          </Typography>
        </div>

        <Container className={classes.headerContainer} fixed>
          <Grid
            id={'upper-header'}
            className={classes.upperHeader}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item className={classes.headerItem + ' ' + classes.logoItem}>
              <div className={'menu-anchor'}>
                <Link to="/">
                  <img src={logo} alt={'hullo'} />
                </Link>
              </div>
            </Grid>

            <Grid item className={classes.headerItem}>
              <div className={'component' + ' ' + 'user-info'}>
                {checkUserCookie() && (
                  <div className={'component-inner'}>
                    <div className={'image-wrapper'}>
                      <img src={userIcon} alt={'user'} />
                      <div
                        className={'hover-menu'}
                        style={{ paddingTop: upperPaddingTop }}
                      >
                        <div className={'hover-menu-content'}>
                          <Typography
                            className={'hover-menu-item'}
                            variant={'body2'}
                          >
                            <Link to={'/profil/'}>Twój profil</Link>
                          </Typography>
                          <Typography
                            className={'hover-menu-item'}
                            variant={'body2'}
                          >
                            <Link to={'/profil/'}>Program lojalnościowy</Link>
                          </Typography>
                          <Typography
                            className={'hover-menu-item'}
                            variant={'body2'}
                          >
                            <Link to={process.env.REACT_APP_WWW_URL + '/faq/'}>
                              FAQ
                            </Link>
                          </Typography>
                          <Typography
                            className={'hover-menu-item'}
                            variant={'body2'}
                          >
                            <a href={'https://hullo.pl'} target={'_blank'}>
                              hullo.pl
                            </a>
                          </Typography>
                        </div>
                      </div>
                    </div>

                    <div className={'content-wrapper'}>
                      <Typography className={'user-name'}>
                        {
                          cookies.get('userData')['description']['data'][
                            'displayName'
                          ]
                        }
                      </Typography>
                      <Typography className={'user-role'}>
                        {t(
                          cookies.get('userData')['description']['data']['role']
                        ) +
                          ' (ID: ' +
                          cookies.get('userData')['description']['data']['id'] +
                          ')'}
                      </Typography>
                    </div>
                  </div>
                )}{' '}
              </div>
            </Grid>

            <Grid
              item
              className={classes.headerItem + ' ' + classes.resizeLettersItem}
            >
              <div>
                <button
                  onClick={() => {
                    document.documentElement.style.fontSize = '8px';
                  }}
                >
                  A
                </button>
                <button
                  onClick={() => {
                    document.documentElement.style.fontSize = '10px';
                  }}
                >
                  A
                </button>
                <button
                  onClick={() => {
                    document.documentElement.style.fontSize = '12px';
                  }}
                >
                  A
                </button>
              </div>
            </Grid>

            <Grid item className={classes.headerItem + ' login-item'}>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                message={`Wylogowano poprawnie`}
                action={action}
              />
              {!checkUserCookie() ? (
                <Link to="/login">
                  <Button variant={'outlined'}>Zaloguj się</Button>
                </Link>
              ) : (
                <>
                  <Button onClick={userLogout} variant={'outlined'}>
                    Wyloguj się
                  </Button>
                </>
              )}
            </Grid>

            <Hamburger toggled={isOpen} toggle={setOpen} />
            <p>{isOpen}</p>
          </Grid>
          <Grid
            className={classes.lowerHeader + `${isOpen ? ' active-menu' : ''}`}
            container
            // direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item className={classes.navItem}>
              <div className={'menu-anchor'}>
                <Typography>
                  <Link to="/aktualnosci">Aktualności</Link>
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.navItem}>
              <div className={'menu-anchor'}>
                <Typography>
                  <Link to="/rehabilitacje">Rehabilitacje</Link>
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.navItem}>
              <div className={'menu-anchor'}>
                <Typography>
                  <Link to="/cwiczenia">Ćwiczenia</Link>
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.navItem}>
              <div className={'menu-anchor'}>
                <Typography>
                  <Link to="/pakiety">Pakiety</Link>
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.navItem}>
              <div className={'menu-anchor'}>
                <Typography>
                  <Link to="/gry-ciekawostki">Gry i ciekawostki</Link>
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.navItem}>
              <div className={'menu-anchor'}>
                <Typography>
                  <Link to="/kontakt">Kontakt</Link>
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.headerItem + ' login-item'}>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                message={`Wylogowano poprawnie`}
                action={action}
              />
              {!checkUserCookie() ? (
                <Link to="/login">
                  <Button variant={'outlined'}>Zaloguj się</Button>
                </Link>
              ) : (
                <>
                  <Button onClick={userLogout} variant={'outlined'}>
                    Wyloguj się
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </header>
    </>
  );
}

export default Header;

import React, { useState, useEffect } from 'react';
import useStyles from './News.style';
import { Button, Container, Grid, Typography } from '@mui/material';
import { generateHelmet } from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import { Link } from 'react-router-dom';
import NewsCard from '../../../components/Modules/News/NewsCard/NewsCard';
import Footer from '../../../components/General/Footer/Footer';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import getPosts from '../../../api/getPosts';
import { BallTriangle } from 'react-loader-spinner';

function NewsIndex() {
  const classes = useStyles();

  const [posts, setPosts] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPosts(`?per_page=6`).then((result) => {
      setPosts(result);
      setLoading(false);
      // console.log(result);
    });
  }, []);

  return (
    <>
      {generateHelmet('Aktualności', 'Moduł aktualności aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container className={classes.container}>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <BreadcrumbsComponent child="Aktualności" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <>
                {loading && (
                  <div className={classes.loaderWrapper}>
                    <BallTriangle
                      heigth="70"
                      width="70"
                      color="#27BDBE"
                      ariaLabel="loading-indicator"
                    />
                  </div>
                )}
                {!loading &&
                  posts.map(
                    (post) =>
                      (post = (
                        <Grid item sm={6} xs={12}>
                          <NewsCard
                            title={post.title.rendered}
                            key={post.id}
                            date={post.formatted_date}
                            excerpt={post.excerpt.rendered}
                            slug={post.slug}
                            imageUrl={post.formatted_media}
                          />
                        </Grid>
                      ))
                  )}
              </>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}></Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default NewsIndex;

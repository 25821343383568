import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '2rem',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      marginTop: '1rem',
      '& h5': {
        backgroundColor: '#D4F2F2',
        borderRadius: '1rem',
        padding: '1rem',
        display: 'inline-block',
        color: '#27BDBE',
        marginBottom: '0',
        marginRight: '1rem',
      },
      '& span': {
        color: '#8E979C',
      },
    },
  },
});

export default useStyles;

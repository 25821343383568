import React, { useState, useEffect } from 'react';
import useStyles from './News.style';
import { Button, Container, Grid, Typography } from '@mui/material';
import { generateHelmet } from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import { Link } from 'react-router-dom';
import NewsCard from '../../../components/Modules/News/NewsCard/NewsCard';
import NewsIntro from '../../../components/Modules/News/NewsIntro/NewsIntro';
import Footer from '../../../components/General/Footer/Footer';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import { useParams } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import getPosts from '../../../api/getPosts';

function SingleNews() {
  const classes = useStyles();
  let params = useParams();

  const [post, setPost] = useState(false);
  const [otherPosts, setOtherPosts] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingOtherPosts, setLoadingOtherPosts] = useState(true);

  useEffect(() => {
    getPosts(`?slug=${params.postSlug}`).then((result) => {
      setPost(result[0]);
      setLoading(false);
    });
  }, [params]);

  useEffect(() => {
    if (!loading) {
      getPosts(`?per_page=3&exclude=${post.id}`).then((result) => {
        setOtherPosts(result);
        setLoadingOtherPosts(false);
      });
    }
  }, [loading]);

  return (
    <>
      {generateHelmet('Aktualności', 'Moduł aktualności aplikacji hullo')}
      <Header />
      <main>
        <section>
          <Container>
            {loading && (
              <div className={classes.loaderWrapper}>
                <BallTriangle
                  heigth="70"
                  width="70"
                  color="#27BDBE"
                  ariaLabel="loading-indicator"
                />
              </div>
            )}
            {!loading && (
              <>
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <BreadcrumbsComponent
                      parent="Aktualności"
                      parentLink="/aktualnosci"
                      child={post.title.rendered}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <NewsIntro
                      title={post.title.rendered}
                      date={post.formatted_date}
                      image={post.formatted_media}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={2} xs={12} />
                  <Grid item lg={8} xs={12}>
                    <div className={classes.content}>
                      <Typography
                        variant={'body2'}
                        dangerouslySetInnerHTML={{
                          __html: post.content.rendered,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={2} xs={12} />{' '}
                </Grid>

                <Grid container spacing={3}>
                  <Grid item lg={1} xs={12} />
                  <Grid item lg={10} xs={12}>
                    <Typography variant={'h4'}>
                      Sprawdź inne aktualności
                    </Typography>
                    <div className={classes.otherNews}>
                      {!loadingOtherPosts &&
                        otherPosts.map(
                          (post) =>
                            (post = (
                              <div className="wrapper">
                                <NewsCard
                                  title={post.title.rendered}
                                  key={post.id}
                                  date={post.formatted_date}
                                  slug={`/aktualnosci/${post.slug}`}
                                  imageUrl={post.formatted_media}
                                />
                              </div>
                            ))
                        )}
                    </div>
                  </Grid>
                  <Grid item lg={1} xs={12} />{' '}
                </Grid>
              </>
            )}
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default SingleNews;

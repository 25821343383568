import React from 'react';
import useStyles from './Games.style';
import {Container, Grid, Typography} from '@mui/material';
import {generateHelmet} from '../../../utils/helperFunctions';
import Header from '../../../components/General/Header/Header';
import BreadcrumbsComponent from '../../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import Footer from '../../../components/General/Footer/Footer';
import GameCard from '../../../components/Modules/Games/GameCard/GameCard';
import LastAddedPointsBox from '../../../components/Modules/Games/LastAddedPointsBox/LastAddedPointsBox';
import AddGameForm from '../../../components/Modules/Games/AddGameForm/AddGameForm';
import PaginationComponent from '../../../components/General/PaginationComponent/PaginationComponent';
import HulloPoints from "../../../components/General/HulloPoints/HulloPoints";

function GamesIndex() {
    const classes = useStyles();
    return (
        <>
            {generateHelmet(
                'Gry i ciekawostki',
                'Moduł gier i ciekawostek aplikacji hullo'
            )}
            <Header/>
            <main>
                <section>
                    <Container className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <BreadcrumbsComponent child="Gry i ciekawostki"/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Typography component="h1" variant={'h3'}>
                                    Gry i ciekawostki
                                </Typography>
                                {/*<div className={classes.buttonsWrapper}>*/}
                                {/*  <button className="active">Gry</button>*/}
                                {/*  <button>Ciekawostki</button>*/}
                                {/*</div>*/}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={9} xs={12}>
                                <div className={classes.gamesWrapper}>
                                    <div className="wrapper-inner">
                                        <GameCard popular title={'Ciekawostka nr 1'} description={'Dorosły człowiek ma 2000-4000 kubków smakowych. Co ciekawe, znajdują się nie tylko na języku. Te maleńkie narządy zmysłu, które dają nam zdolność odczuwania smaku, mieszczą się również w tylnej części gardła, nosie i przełyku. Węch jest bardzo ważnym składnikiem degustacji, a płcią która lepiej go wyczuwa są kobiety. Wśród populacji aż 2%, czyli około 15,6 mln ludzi nie potrafi odczuwać zapachów, co może stanowić zagrożenie dla życia. Mężczyźni mimo nieco mniej czułego zmysłu powonienia mają zdecydowanie lepszy smak, częściej zostają szefami kuchni najlepszych restauracji na świecie.'} points={0} unlocked={true}/>
                                    </div>
                                    <div className="wrapper-inner">
                                        <GameCard title={'Sala Zabaw (1h)'} description={''} points={600} unlocked={false} />
                                    </div>
                                    <div className="wrapper-inner">
                                        <GameCard title={'Pokój laserowy (30min)'} description={''} points={1000} unlocked={false}/>
                                    </div>
                                    <div className="wrapper-inner">
                                        <GameCard title={'Pokój VR (45min'} description={''} points={1200} unlocked={false}/>
                                    </div>
                                    <div className="wrapper-inner">
                                        <GameCard popular title={'Ścianka wspinaczkowa (1h)'} description={''} points={1800} unlocked={false}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <HulloPoints />
                                {/*<LastAddedPointsBox/>*/}
                            </Grid>
                        </Grid>
                        {/*<Grid container spacing={3}>
                          <Grid item lg={7} xs={12}>
                            <AddGameForm></AddGameForm>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item lg={12} xs={12}>
                            <PaginationComponent></PaginationComponent>
                          </Grid>
                        </Grid>*/}
                    </Container>
                </section>
                <Footer></Footer>
            </main>
        </>
    );
}

export default GamesIndex;

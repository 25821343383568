import React from 'react';
import useStyles from './Static.style';
import { Container, Grid, Typography } from '@mui/material';
import { generateHelmet } from '../../utils/helperFunctions';
import Header from '../../components/General/Header/Header';
import BreadcrumbsComponent from '../../components/General/BreadcrumbsComponent/BreadcrumbsComponent';
import clockSvg from '../../assets/svg/clock.svg';
import pinSvg from '../../assets/svg/pin.svg';
import mailSvg from '../../assets/svg/mail.svg';
import contactSvg from '../../assets/svg/contact.svg';
import Footer from '../../components/General/Footer/Footer';

function Contact() {
  const classes = useStyles();
  return (
    <>
      {generateHelmet('Kontakt', 'Masz pytania do aplikacji hullo?')}
      <Header />
      <main>
        <section className={classes.contactSection}>
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <BreadcrumbsComponent child="Kontakt" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <Typography variant={'h3'}>Kontakt</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={3} md={6} xs={12}>
                <div className="info-box">
                  <img src={contactSvg} alt="" />

                  <Typography variant={'h6'}>Zadzwoń do nas</Typography>
                  <a href="tel:+48 512 909 172">
                    +48 512 909 172 (część sportowa)
                  </a>
                  <br />
                  <a href="tel:+48 797 707 693">
                    +48 797 707 693 (część rekreacyjna)
                  </a>
                </div>
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <div className="info-box">
                  <img src={mailSvg} alt="" />

                  <Typography variant={'h6'}>Napisz do nas</Typography>
                  <a href="mailto:recepcja@hullocentrum.pl">
                    recepcja@hullocentrum.pl
                  </a>
                </div>
              </Grid>
              <Grid item lg={5} md={6} xs={12}>
                <div className="info-box">
                  <img src={pinSvg} alt="" />

                  <Typography variant={'h6'}>Odwiedź nas</Typography>
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/place/Fijewo+85,+14-260+Fijewo/@53.4990743,19.7544034,19.5z/data=!4m5!3m4!1s0x471d6cce28110c47:0x1a4df1ad2619cfdc!8m2!3d53.4991034!4d19.754418"
                  >
                    Fijewo 85A, 14-260 Lubawa <br />
                    (Wjazd od ul. Sadowej)
                  </a>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <div className="info-box">
                  {' '}
                  <img src={clockSvg} alt="" />
                  <Typography variant={'h6'}>
                    Godziny otwarcia
                    <br /> Budynek Rehabilitacyjno - Sportowy
                  </Typography>
                  <p>Pn - Pt / 08:00 - 21:00 </p>
                  <p>Sobota / 08:00 - 16:00 </p>
                  <p>Niedziela / nieczynne</p>
                </div>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <div className="info-box">
                  <img src={clockSvg} alt="" />
                  <Typography variant={'h6'}>
                    Godziny otwarcia <br /> Budynek Rekreacyjno - Edukacyjny
                  </Typography>
                  <p>Pn - Pt / 10:00 - 18:00 </p>
                  <p>Sobota / 10:00 - 20:00 </p>
                  <p>Niedziela / 10:00 - 18:00</p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer hideHours />
    </>
  );
}

export default Contact;

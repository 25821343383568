import React from 'react';
import { createUseStyles } from 'react-jss';
import theme from '../../../../theme';

const useStyles = createUseStyles({
  box: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '2.5rem',
    boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.05)',
    '& > div:not(.answer)': {
      boxShadow: 'none',
      borderRadius: '0',
      //   border: '1px solid red !important',
      borderTop: '2px solid #EFEFEF',
      '&:first-of-type': {
        borderTop: 'unset',
      },
    },
    '& button': {
      marginLeft: '2rem',
    },
    '& .answer': {
      // marginTop: '2rem',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& button': {
        marginLeft: '0',
        marginTop: '1rem',
        backgroundColor: '#27BDBE',
        '&:hover': {
          backgroundColor: '#A9E5E5',
        },
        '&.Mui-disabled': {
          backgroundColor: '#A9E5E5',
          '&:hover': {
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
});

export default useStyles;

import React, { useState, useEffect } from 'react';
import useStyles from './NewsFeed.style';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';
import getPosts from '../../../../api/getPosts';

function NewsFeed(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [posts, setPosts] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPosts(`?per_page=3`).then((result) => {
      setPosts(result);
      setLoading(false);
      // console.log(result);
    });
  }, []);

  return (
    <div
      className={classes.root + ' ' + 'component' + ' ' + 'newsfeed-component'}
    >
      <div className={classes.feedWrapper + ' ' + 'component-inner'}>
        {loading && (
          <div className={classes.loaderWrapper}>
            <BallTriangle
              heigth="70"
              width="70"
              color="#27BDBE"
              ariaLabel="loading-indicator"
            />
          </div>
        )}

        {!loading &&
          posts.map(
            (post) =>
              (post = (
                <div
                  key={post.id}
                  className={
                    classes.singleNews +
                    ' ' +
                    'component' +
                    ' ' +
                    'singlenews-component'
                  }
                >
                  <div className={'component-inner'}>
                    <div className={'image-wrapper'}>
                      <Link to={`/aktualnosci/${post.slug}`}>
                        <img src={post.formatted_media} alt={''} />
                      </Link>
                    </div>
                    <div className={'content-wrapper'}>
                      <Typography variant={'caption'}>
                        {post.formatted_date}
                      </Typography>
                      <Typography variant={'h6'}>
                        <Link to={`/aktualnosci/${post.slug}`}>
                          {post.title.rendered}
                        </Link>
                      </Typography>
                    </div>
                  </div>
                </div>
              ))
          )}
      </div>
    </div>
  );
}

export default NewsFeed;
